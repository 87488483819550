import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  createVideoDiscussionAnswer,
  createVideoDiscussionQuestion,
  deleteVideoDiscussionAnswer,
  deleteVideoDiscussionQuestion,
  getVideoDiscussions,
  updateVideoDiscussionAnswer,
  updateVideoDiscussionQuestion,
  voteDiscussionAnswer,
  voteDiscussionQuestion,
} from "../api/content";
import withAuth from "../hoc/withAuth";
import { sortByDescending } from "../utils/object";
import Loading from "./utils/Loading";
import Message from "./utils/Message";
import { OptionsButton } from "./utils/buttons";
import { InputSend } from "./utils/inputs";

const Vote = ({
  voteCount,
  onUpvote,
  onDownvote,
  userUpvoted,
  userDownvoted,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        px: 0.5,
      }}
    >
      <IconButton
        size="small"
        onClick={onUpvote}
        color={userUpvoted ? "primary" : "default"}
      >
        <ArrowUpwardRoundedIcon />
      </IconButton>
      <Typography variant="h6">{voteCount}</Typography>
      <IconButton
        size="small"
        onClick={onDownvote}
        color={userDownvoted ? "primary" : "default"}
      >
        <ArrowDownwardRoundedIcon />
      </IconButton>
    </Box>
  );
};

const QuestionRow = ({
  obj,
  user,
  videoId,
  onUpvote,
  onDownvote,
  openAnswersModal,
  hideOptions,
  setDiscussions,
}) => {
  const [editMode, setEditMode] = useState(false);

  const isOwner = obj.question.user._id === user._id;

  const onUpdateQuestion = async (newText) => {
    const res = await updateVideoDiscussionQuestion({
      videoId,
      discussionId: obj._id,
      text: newText,
    });
    setDiscussions((discussions) =>
      discussions.map((d) => {
        if (d._id === obj._id) {
          return res.discussion;
        }
        return d;
      })
    );
    setEditMode(false);
  };

  const onDelete = async () => {
    await deleteVideoDiscussionQuestion({
      videoId,
      discussionId: obj._id,
    });
    setDiscussions((discussions) =>
      discussions.filter((d) => d._id !== obj._id)
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", width: "100%", p: 1 }}>
      <Vote
        voteCount={obj.question.upvotes.length - obj.question.downvotes.length}
        onUpvote={onUpvote}
        onDownvote={onDownvote}
        userUpvoted={obj.question.upvotes.includes(user._id)}
        userDownvoted={obj.question.downvotes.includes(user._id)}
      />
      <Divider orientation="vertical" flexItem sx={{ mx: 1.5 }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography variant="subtitle1">
            {obj.question.user.name} {isOwner ? "(You)" : null}
          </Typography>
          {isOwner && (
            <OptionsButton
              actions={[
                {
                  title: "Edit",
                  onClick: () => setEditMode(true),
                  icon: <EditIcon />,
                },
                {
                  title: "Delete",
                  onClick: onDelete,
                  icon: <DeleteIcon color="error" />,
                },
              ]}
            />
          )}
        </Box>
        <Box>
          {!editMode ? (
            <Typography
              variant="h6"
              component="div"
              fontWeight="semibold"
              mt={1}
              mb={1}
            >
              {obj.question.text}
            </Typography>
          ) : (
            <InputSend
              initialValue={obj.question.text}
              onSubmit={onUpdateQuestion}
            />
          )}
        </Box>
        {hideOptions ? null : (
          <Box>
            <Button
              variant="text"
              size="small"
              sx={{ mr: 2 }}
              onClick={() => openAnswersModal(false)}
            >
              Show Answers
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={() => openAnswersModal(true)}
            >
              Answer
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const AnswerRow = ({
  obj,
  user,
  onUpvote,
  onDownvote,
  onUpdateAnswer,
  onDelete,
}) => {
  const [editMode, setEditMode] = useState(false);

  const isOwner = obj.user._id === user._id;

  const onEdit = async (newText) => {
    await onUpdateAnswer(newText);
    setEditMode(false);
  };

  return (
    <Card variant="outlined" sx={{ my: 1 }}>
      <CardHeader
        avatar={<Avatar src={obj.user.avatar} alt={obj.user.name} />}
        action={
          isOwner && (
            <OptionsButton
              actions={[
                {
                  title: "Edit",
                  onClick: () => setEditMode(true),
                  icon: <EditIcon />,
                },
                {
                  title: "Delete",
                  onClick: onDelete,
                  icon: <DeleteIcon color="error" />,
                },
              ]}
            />
          )
        }
        title={`${obj.user.name.toUpperCase()} ${isOwner ? "(You)" : ""}`}
        subheader={obj.user.email}
      />
      <CardContent sx={{ display: "flex", flexDirection: "row", pt: 0, pb: 0 }}>
        <Vote
          voteCount={obj.upvotes.length - obj.downvotes.length}
          onUpvote={onUpvote}
          onDownvote={onDownvote}
          userUpvoted={obj.upvotes.includes(user._id)}
          userDownvoted={obj.downvotes.includes(user._id)}
        />
        <Divider orientation="vertical" flexItem sx={{ mx: 1.5 }} />

        <Box sx={{ width: "100%" }}>
          {!editMode ? (
            <Typography variant="body1" fontWeight="semibold" mt={1}>
              {obj.text}
            </Typography>
          ) : (
            <InputSend initialValue={obj.text} onSubmit={onEdit} />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

const QuestionDetails = ({
  user,
  videoId,
  obj,
  discussions,
  setDiscussions,
  inputRef,
}) => {
  const questionId = obj.question._id;

  const onCreateAnswer = async (newDiscussion) => {
    if (!newDiscussion || !newDiscussion.trim()) return;
    const res = await createVideoDiscussionAnswer({
      videoId,
      text: newDiscussion.trim(),
      questionId,
    });

    const newDiscussions = discussions.map((d) => {
      if (d.question._id === questionId) {
        return res.discussion;
      }
      return d;
    });
    setDiscussions(newDiscussions);
  };

  const onUpdateAnswer = async (answerId, text) => {
    const res = await updateVideoDiscussionAnswer({
      videoId,
      questionId,
      text,
      answerId,
    });
    setDiscussions((discussions) =>
      discussions.map((d) => {
        if (d.question._id === questionId) {
          return res.discussion;
        }
        return d;
      })
    );
  };

  const onDeleteAnswer = async (answerId) => {
    await deleteVideoDiscussionAnswer({
      videoId,
      questionId,
      answerId,
    });
    setDiscussions((discussions) =>
      discussions.map((d) => {
        if (d.question._id === questionId) {
          return {
            ...d,
            answers: d.answers.filter((a) => a._id !== answerId),
          };
        }
        return d;
      })
    );
  };

  const upvoteAnswer = (answerId) => {
    const answer = obj.answers.find((a) => a._id === answerId);
    voteDiscussionAnswer({
      videoId,
      questionId,
      answerId,
      vote: answer.upvotes.includes(user._id) ? 0 : 1,
    }).then((res) => {
      answer.upvotes = res.upvotes;
      answer.downvotes = res.downvotes;
      setDiscussions([...discussions]);
    });
  };

  const downvoteAnswer = (answerId) => {
    const answer = obj.answers.find((a) => a._id === answerId);
    voteDiscussionAnswer({
      videoId,
      questionId,
      answerId,
      vote: answer.downvotes.includes(user._id) ? 0 : -1,
    }).then((res) => {
      answer.upvotes = res.upvotes;
      answer.downvotes = res.downvotes;
      setDiscussions([...discussions]);
    });
  };

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Typography variant="h6" component="div">
        {obj.question.text}
      </Typography>

      <Typography variant="subtitle2" component="h2" mt={2}>
        Answers:
      </Typography>
      <Divider />

      <Box
        sx={{
          flexGrow: 1,
          overflowY: "scroll",
        }}
      >
        {sortByDescending(
          obj.answers.map((ans) => ({
            ...ans,
            voteCount: ans.upvotes.length - ans.downvotes.length,
          })),
          "voteCount"
        ).map((a) => (
          <AnswerRow
            key={a._id}
            obj={a}
            user={user}
            onUpvote={() => upvoteAnswer(a._id)}
            onDownvote={() => downvoteAnswer(a._id)}
            onUpdateAnswer={(text) => onUpdateAnswer(a._id, text)}
            onDelete={() => onDeleteAnswer(a._id)}
          />
        ))}
      </Box>
      <InputSend onSubmit={onCreateAnswer} />
    </Box>
  );
};

const Discussions = ({ user, videoId }) => {
  const [discussions, setDiscussions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAnswersModal, setShowAnswersModal] = useState(null);

  const answerInputRef = useRef(null);

  useEffect(() => {
    getVideoDiscussions(videoId)
      .then((res) => {
        setDiscussions(res.discussions);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [videoId]);

  const onCreateQuestion = async (newDiscussion) => {
    if (!newDiscussion || !newDiscussion.trim()) return;
    const res = await createVideoDiscussionQuestion({
      videoId,
      text: newDiscussion.trim(),
    });
    setDiscussions([...discussions, res.discussion]);
  };

  const upvoteQuestion = (questionId) => {
    const question = discussions.find(
      (d) => d.question._id === questionId
    ).question;
    voteDiscussionQuestion({
      videoId,
      questionId,
      vote: question.upvotes.includes(user._id) ? 0 : 1,
    }).then((res) => {
      question.upvotes = res.upvotes;
      question.downvotes = res.downvotes;
      setDiscussions([...discussions]);
    });
  };

  const downvoteQuestion = (questionId) => {
    const question = discussions.find(
      (d) => d.question._id === questionId
    ).question;
    voteDiscussionQuestion({
      videoId,
      questionId,
      vote: question.downvotes.includes(user._id) ? 0 : -1,
    }).then((res) => {
      question.upvotes = res.upvotes;
      question.downvotes = res.downvotes;
      setDiscussions([...discussions]);
    });
  };

  const openAnswersModal = (questionId, addsAnswer) => {
    // addsAnswer is true if the user wants to add an answer. false if the user wants to see answers
    // text box will be focused if addsAnswer is true
    setShowAnswersModal(questionId);
    if (addsAnswer) answerInputRef.current.focus(); // not working. needs to be fixed
  };

  const closeAnswersModal = () => {
    setShowAnswersModal(null);
  };

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Message message={error} />;
  }

  return (
    <>
      <Modal open={!!showAnswersModal} onClose={closeAnswersModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            backgroundColor: "#ffffffee",
            boxShadow: 24,
            p: 4,
            height: "80%",
            borderRadius: 2,
          }}
        >
          <QuestionDetails
            inputRef={answerInputRef}
            user={user}
            videoId={videoId}
            obj={discussions.find((d) => d.question._id === showAnswersModal)}
            discussions={discussions}
            setDiscussions={setDiscussions}
          />
        </Box>
      </Modal>
      <Paper variant="outlined">
        <Typography variant="h5" sx={{ p: 1, pl: 2 }}>
          Discussions
        </Typography>
        <Divider />
        <Box
          sx={{
            width: "auto",
            display: "flex",
            flexDirection: "column",
            height: "auto",
            p: 1,
            pt: 0,
          }}
        >
          <Box
            sx={{
              maxHeight: 1000,
              overflowY: "scroll",
            }}
          >
            {discussions.length === 0 ? (
              <Message message={"No discussions here"} hideBorder />
            ) : (
              <List aria-label="discussions" sx={{ pt: 0 }}>
                {sortByDescending(
                  discussions.map((obj) => ({
                    ...obj,
                    voteCount:
                      obj.question.upvotes.length -
                      obj.question.downvotes.length,
                  })),
                  "voteCount"
                ).map((d) => (
                  <ListItem divider key={d.question._id} sx={{ px: 0 }}>
                    <QuestionRow
                      obj={d}
                      user={user}
                      videoId={videoId}
                      onUpvote={() => upvoteQuestion(d.question._id)}
                      onDownvote={() => downvoteQuestion(d.question._id)}
                      openAnswersModal={(addsAnswer) =>
                        openAnswersModal(d.question._id, addsAnswer)
                      }
                      setDiscussions={setDiscussions}
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </Box>

          <InputSend onSubmit={onCreateQuestion} />
        </Box>
      </Paper>
    </>
  );
};

export default withAuth(Discussions);
