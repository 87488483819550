import { getReq, postReq } from ".";

export const updateProgress = async () => {
  const progress = localStorage.getItem("progress");
  let progressObj;
  if (progress) {
    try {
      progressObj = JSON.parse(progress);
    } catch (e) {
      console.log(e);
      localStorage.removeItem("progress");
      return;
    }

    progressObj = Object.keys(progressObj).map((subTopicId) => ({
      subTopicId,
      videos: Object.keys(progressObj[subTopicId]).map((videoId) => ({
        videoId,
        playedSeconds: progressObj[subTopicId][videoId]?.playedSeconds || 0,
        playedFraction: progressObj[subTopicId][videoId]?.playedFraction,
      })),
    }));

    await postReq(`/progress/video`, { progress: progressObj }, true);
    localStorage.removeItem("progress");
  }
};

export const getCompleteProgress = async () => {
  const { data } = await getReq(`/progress`, {}, true);
  return data;
};
