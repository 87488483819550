import { Box, Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { createTest, deleteTestProfile, getTestProfiles } from "../api/test";
import routes from "../layout/routes";
import TestProfileCard from "../components/TestProfileCard";
import CreateTestFromProfilePopup from "../components/popups/CreateTestFromProfilePopup";

const TestProfiles = () => {
  const navigate = useNavigate();
  const [testProfiles, setTestProfiles] = useState([]);
  const [showCreateTestDialog, setShowCreateTestDialog] = useState(null);

  useEffect(() => {
    getTestProfiles().then((res) => {
      setTestProfiles(
        res.testProfiles.reduce((acc, curr) => {
          acc[curr._id] = curr;
          return acc;
        }, {})
      );
    });
  }, []);

  const createTestFromProfile = (id) => {
    setShowCreateTestDialog(id);
  };
  const onCreateTestSubmit = (data) => {
    createTest({ ...testProfiles[showCreateTestDialog], ...data }).then(
      (res) => {
        console.log(res);
        navigate(routes.tests.path);
      }
    );
  };
  const onDeleteTestProfile = (id) => {
    deleteTestProfile(id).then(() => {
      setTestProfiles((testProfiles) => {
        const newTestProfiles = { ...testProfiles };
        delete newTestProfiles[id];
        return newTestProfiles;
      });
    });
  };
  const onExpand = (id) => {};

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        flex: 1,
        mt: 2,
        pr: 15,
        pl: 15,
        [theme.breakpoints.down("md")]: {
          p: 2,
          m: 1,
        },
      })}
    >
      {showCreateTestDialog && (
        <CreateTestFromProfilePopup
          open={!!showCreateTestDialog}
          onSubmit={onCreateTestSubmit}
          onCancel={() => setShowCreateTestDialog(null)}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <Typography variant="h5">Test Profiles</Typography>
        <Button onClick={() => navigate(routes.createTestProfile.path)}>
          Create New
        </Button>
      </Box>

      <Grid sx={{ mt: 2 }} container spacing={1}>
        {Object.values(testProfiles).map((tp) => (
          <Grid item key={tp._id} xs={12} s={6} md={6} lg={4} xl={3}>
            <TestProfileCard
              key={tp._id}
              obj={tp}
              onCreateTest={() => createTestFromProfile(tp._id)}
              onDelete={() => onDeleteTestProfile(tp._id)}
              onExpand={() => onExpand(tp._id)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TestProfiles;
