import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import { Box, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import XLink from "./link";

const Message = ({
  message,
  hideBorder,
  icon,
  extra,
  redirectTime = 5,
  redirectTo,
}) => {
  const [redirectSeconds, setRedirectSeconds] = useState(redirectTime);
  const navigate = useNavigate();

  useEffect(() => {
    if (!redirectTo) {
      return;
    }

    const interval = setInterval(() => {
      setRedirectSeconds(redirectSeconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [redirectSeconds]);

  if (redirectTo && redirectSeconds <= 0) {
    if (redirectTo.external) {
      window.location.href =
        redirectTo.path + "?returnTo=" + window.location.href;
      return;
    }
    navigate(redirectTo);
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: "90%",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <Paper
        sx={{
          width: "50%",
          height: "20%",
          padding: "8%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          flexDirection: "column",
          border: hideBorder ? "none" : "1px solid #e0e0e0",
        }}
        variant="outlined"
      >
        {icon ? icon : <AllInclusiveIcon fontSize="large" />}
        <Typography variant="h6" component="div" gutterBottom>
          {message}
        </Typography>
        {redirectTo && (
          <>
            <Typography variant="body1" component="div" gutterBottom>
              Redirecting to{" "}
              <XLink to={redirectTo.path} external={redirectTo.external}>
                {redirectTo.title} page
              </XLink>{" "}
              in {redirectSeconds >= 0 ? redirectSeconds : 0} seconds.
            </Typography>

            <Typography variant="body1" component="div">
              If not automatically redirected, click{" "}
              <XLink to={redirectTo.path} external={redirectTo.external}>
                here
              </XLink>
              .
            </Typography>
          </>
        )}
        {extra && <Box sx={{ height: "10%" }}>{extra}</Box>}
      </Paper>
    </Box>
  );
};

export default Message;
