import LockIcon from "@mui/icons-material/Lock";
import { LoadingButton } from "@mui/lab";
import { ListSubheader } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { useState } from "react";
import { createPreparationPracticeTest } from "../../api/test";
import { testLink, testReportLink } from "../../utils/constants";

const PreparationPlanTestCard = ({
  testUnlocked,
  preparationPlanId,
  dayItem,
  dayNumber,
  tests,
}) => {
  const [loading, setLoading] = useState(false);

  const onStartTest = async () => {
    // TODO: move this logic to backend for better security
    const test = {
      title: `Day ${dayNumber} Practice Test`,
      sections: dayItem.subTopics.map((subTopic) => ({
        title: subTopic.title,
        subTopicIds: [subTopic._id],
        duration: 10,
        numOfQuestions: 10,
      })),
      startTime: dayjs().add(2, "seconds").toISOString(),
      preparationPlanDetails: {
        preparationPlanId,
        dayItemId: dayItem._id,
      },
    };
    try {
      setLoading(true);
      const res = await createPreparationPracticeTest(test);
      window.open(testLink(res.test._id), "_blank").focus();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const goToTestReportPage = (testId) => {
    window.open(testReportLink(testId), "_blank").focus();
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        m: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: 1,
        }}
      >
        <Typography sx={{ p: 1 }} variant="h6">
          Practice Test
        </Typography>
        {testUnlocked ? (
          <LoadingButton
            variant="outlined"
            onClick={onStartTest}
            loading={loading}
          >
            Start
          </LoadingButton>
        ) : (
          <Tooltip title="Complete previous days topics to unlock the test.">
            <Button
              variant="outlined"
              startIcon={<LockIcon />}
              loading={loading}
            >
              Start
            </Button>
          </Tooltip>
        )}
      </Box>
      <Divider />

      <List>
        {dayItem.subTopics.map((subTopic) => (
          <ListItem key={subTopic._id}>
            <ListItemText primary={subTopic.title} />
          </ListItem>
        ))}
      </List>
      {tests?.length > 0 && (
        <>
          <Divider />

          <List
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Attempts
              </ListSubheader>
            }
          >
            {tests.map((test) => (
              <ListItem
                key={test._id}
                secondaryAction={
                  <Button
                    edge="end"
                    aria-label="show report"
                    onClick={() => goToTestReportPage(test._id)}
                  >
                    View Detailed Report
                  </Button>
                }
              >
                <ListItemText
                  primary={test.title}
                  secondary={`Score: ${test.score.score}/${test.score.total}`}
                />
              </ListItem>
            ))}
          </List>
        </>
      )}
    </Paper>
  );
};

export default PreparationPlanTestCard;
