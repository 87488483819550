import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import QuizIcon from "@mui/icons-material/Quiz";
import WorkIcon from "@mui/icons-material/Work";
import {
  Box,
  Divider,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  SwipeableDrawer,
} from "@mui/material";
import { blueGrey, grey, teal } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import withAuth from "../hoc/withAuth";
import Logo from "./Logo";
import { MenuButton } from "./NavBar";
import routes from "./routes";

const sidebarItems = {
  apps: [
    {
      title: "Account",
      path: process.env.REACT_APP_AUTH_WEB,
      primary: grey["700"],
      secondary: grey["100"],
      icon: <AccountCircleIcon sx={{ color: grey["900"] }} />,
    },
    {
      title: "Test",
      path: process.env.REACT_APP_TEST_WEB,
      primary: teal["400"],
      secondary: teal["50"],
      icon: <QuizIcon sx={{ color: teal["700"] }} />,
    },
    {
      title: "Path",
      path: process.env.REACT_APP_PATH_WEB,
      primary: blueGrey["400"],
      secondary: blueGrey["50"],
      icon: <WorkIcon sx={{ color: blueGrey["700"] }} />,
    },
  ],
  pages: Object.values(routes).filter((route) => route.sidebar),
  test: [
    routes.tests,
    routes.createTest,
    routes.testProfiles,
    routes.createTestProfile,
  ],
};

const Sidebar = ({ open, toggleDrawer, isSuperAdmin, isStaffAdmin }) => {
  const navigate = useNavigate();

  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
    >
      <Paper
        elevation={6}
        square
        sx={(theme) => ({
          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: 3,
          paddingTop: 1,
          paddingBottom: 1,
          [theme.breakpoints.down("md")]: {
            padding: 2,
            pt: 0.5,
            pb: 0.5,
          },
        })}
      >
        <MenuButton onClick={toggleDrawer} />
        <Logo />
      </Paper>

      <Box
        sx={{
          width: 280,
          padding: 1,
          paddingLeft: 0.5,
        }}
        role="presentation"
        onClick={toggleDrawer}
      >
        <List
          sx={{
            padding: 1,
          }}
          subheader={<ListSubheader>Apps</ListSubheader>}
        >
          {sidebarItems.apps.map(
            ({ title, path, icon, primary, secondary }, index) => (
              <Link
                key={index}
                href={path}
                underline="none"
                target="_blank"
                rel="noreferrer"
                variant="inherit"
                color="inherit"
              >
                <ListItem disablePadding>
                  <ListItemButton
                    sx={{
                      borderRadius: 1.5,
                      backgroundColor: secondary,
                      my: 0.5,
                      border: "1px solid #fff",
                      "&:hover": {
                        border: "1px solid #ccc",
                        backgroundColor: "white",
                      },
                    }}
                  >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={title} />
                  </ListItemButton>
                </ListItem>
              </Link>
            )
          )}
        </List>

        <Divider />

        <List
          sx={{
            padding: 1,
          }}
          subheader={<ListSubheader>Pages</ListSubheader>}
        >
          {sidebarItems.pages.map(({ title, path }, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton onClick={() => navigate(path)}>
                <ListItemText primary={title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        {(isStaffAdmin || isSuperAdmin) && (
          <>
            <Divider />

            <List
              sx={{
                padding: 1,
              }}
              subheader={<ListSubheader>Test</ListSubheader>}
            >
              {sidebarItems.test.map(({ title, path }, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton onClick={() => navigate(path)}>
                    <ListItemText primary={title} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </>
        )}
      </Box>
    </SwipeableDrawer>
  );
};

export default withAuth(Sidebar);
