import { useState, useEffect, forwardRef } from "react";
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slide,
  TextField,
  Toolbar,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { createArticle } from "../api/content";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CloseIcon from "@mui/icons-material/Close";
import Editor from "./Editor";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ArticleForm = ({ open, obj, onSubmit, handleClose }) => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    if (obj) {
      setTitle(obj.title);
      setContent(obj.content);
    }
  }, [obj]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle sx={{ ml: 2, flex: 1 }}>
            {!obj ? "Add" : "Edit"} Article
          </DialogTitle>

          <Button onClick={() => onSubmit({ title, content })} color="inherit">
            Submit
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <DialogContentText>
          {!obj ? "Add" : "Edit"} a article for this subtopic.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="title"
          label="Title"
          type="text"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <Editor value={content} onChange={setContent} />
        {/* <CKEditor
          editor={ClassicEditor}
          data={content}
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            // console.log("Editor is ready to use!", editor);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            // console.log({ data });
            setContent(data);
          }}
        /> */}
      </DialogContent>
    </Dialog>
  );
};

const AddArticle = ({ subTopicId, onAdd }) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAdd = (data) => {
    createArticle({ subTopicId, ...data }).then((res) => {
      console.log(res);
      onAdd?.(res.article);
      handleClose();
    });
  };

  return (
    <>
      <ArticleForm open={open} onSubmit={handleAdd} handleClose={handleClose} />
      <Button
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          p: 2,
          height: "auto",
        }}
        onClick={handleClickOpen}
      >
        <AddIcon sx={{ height: 60, width: 60, color: "primary" }} />
        Add new article
      </Button>
    </>
  );
};

export default AddArticle;
