import React, { useContext, useState } from "react";
import {
  Box,
  Radio,
  Typography,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Collapse,
  CardContent,
  CardActions,
  Button,
  Card,
} from "@mui/material";
import CorrectIcon from "@mui/icons-material/Check";
import WrongIcon from "@mui/icons-material/Close";
import { StoreContext } from "../App";
import { deleteQuestion } from "../api/content";
import QuestionForm from "./QuestionForm";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const Option = ({ obj, index, selectedOption }) => {
  return (
    // eslint-disable-next-line eqeqeq
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <FormControlLabel
        value={index}
        control={<Radio />}
        label={
          <div
            style={{ height: "auto", width: "100%" }}
            dangerouslySetInnerHTML={{ __html: obj.text }}
          />
        }
      />
      {selectedOption && obj.isCorrect && <CorrectIcon color="success" />}
      {String(index) === selectedOption && !obj.isCorrect && (
        <WrongIcon color="error" />
      )}
    </Box>
  );
};

const QuestionCard = ({ obj, color, subTopicId }) => {
  const {
    state: { isAuthenticated, user },
  } = useContext(StoreContext);

  const [editMode, setEditMode] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggleExplanation = () => setExpanded(!expanded);

  const onOptionSelect = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleDeleteQuestion = () => {
    deleteQuestion(obj._id);
  };

  const isSuperAdmin = isAuthenticated && user.role === "superadmin";

  return editMode ? (
    <QuestionForm
      subTopicId={subTopicId}
      obj={obj}
      onCancel={() => setEditMode(false)}
    />
  ) : (
    <Card
      variant="outlined"
      sx={{
        "&:hover": {
          backgroundColor: color,
        },
        width: "95%",
        height: "auto",
        alignItems: "center",
        paddingTop: "1%",
        paddingLeft: "2.5%",
        paddingRight: "2.5%",
      }}
    >
      <CardContent>
        <Box sx={{ marginBottom: 2 }}>
          {/* <Typography
            variant="h6"
            component="div"
            sx={{ fontWeight: "400", fontSize: 18 }}
          >
            {obj.text}
          </Typography> */}
          <div
            style={{ height: "auto", width: "100%" }}
            dangerouslySetInnerHTML={{ __html: obj.text }}
          />
        </Box>
        <FormControl>
          <RadioGroup onChange={onOptionSelect}>
            {obj.options.map((opt, index) => (
              <Option
                key={opt._id}
                obj={opt}
                index={index}
                selectedOption={selectedOption}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </CardContent>

      <CardActions>
        <Button onClick={toggleExplanation}>
          {expanded ? "Hide explanation" : "Show explanation"}
        </Button>
        {isSuperAdmin && (
          <Box sx={{ marginLeft: "auto" }}>
            <Button
              variant="outlined"
              startIcon={<EditIcon />}
              onClick={() => setEditMode(true)}
            >
              Edit
            </Button>
            <Button
              variant="outlined"
              startIcon={<DeleteIcon />}
              color="error"
              onClick={handleDeleteQuestion}
              sx={{ marginLeft: 2 }}
            >
              Delete
            </Button>
          </Box>
        )}
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <div
            style={{ height: "auto", width: "100%" }}
            dangerouslySetInnerHTML={{ __html: obj.explanation }}
          />
          {/* {obj.explanation.split("\n").map((para, index) => (
            <Typography key={index} paragraph>
              {para}
            </Typography>
          ))} */}
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default QuestionCard;
