const assets = {
  english: require("../assets/english.jpeg"),
  aptitude: require("../assets/aptitude.jpeg"),
  logicalReasoning: require("../assets/logical-reasoning.jpeg"),
  verbalAbility: require("../assets/verbal-ability.jpeg"),
  programming: require("../assets/programming.jpeg"),
  cProgramming: require("../assets/c-programming.jpeg"),
  cppProgramming: require("../assets/cpp-programming.jpeg"),
  pythonProgramming: require("../assets/python-programming.png"),
  javaProgramming: require("../assets/java-programming.jpeg"),
  machineLearning: require("../assets/machine-learning.jpeg"),
  artificialIntelligence: require("../assets/artificial-intelligence.jpeg"),
  frontendDevelopment: require("../assets/frontend-development.jpeg"),
  backendDevelopment: require("../assets/backend-development.jpeg"),
  test: require("../assets/test.jpg"),

  //company logos
  tcs: require("../assets/tcs.png"),
  infosys: require("../assets/infosys.png"),
  wipro: require("../assets/wipro.png"),
  cognizant: require("../assets/cognizant.jpeg"),
  accenture: require("../assets/accenture.png"),
  techMahindra: require("../assets/tech-mahindra.png"),
  dxc: require("../assets/dxc.png"),
};

export default assets;
