import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Box, IconButton, Skeleton, Stack } from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteSubTopic,
  editSubTopic,
  editSubTopicPosition,
  useTopicDetails,
} from "../api/content";
import { SubTopicForm } from "../components/AddSubTopic";
import SubTopicCard from "../components/SubTopicCard";
import TopicBigCard from "../components/TopicBigCard";
import topics from "../data/subjects";
import withAuth from "../hoc/withAuth";
import routes from "../layout/routes";
import { lockStatuses } from "../utils/constants";

const Topic = ({ isSuperAdmin }) => {
  const navigate = useNavigate();
  let { topicId } = useParams();
  const [selectedSubTopicId, setSelectedSubTopicId] = useState(null);
  const [subTopicFormOpen, setSubTopicFormOpen] = useState(false);

  const {
    data: { topic, progress, subTopics },
    isLoading,
    mutate,
  } = useTopicDetails(topicId);

  const openSubTopic = (subTopicId) => {
    navigate(routes.subTopicDetail.getPath(topicId, subTopicId));
  };

  const onEditSubTopicClick = (subTopicId) => {
    setSelectedSubTopicId(subTopicId);
    setSubTopicFormOpen(true);
  };

  const onEditSubTopicSubmit = (title, description, locked) => {
    setSubTopicFormOpen(false);
    editSubTopic({
      subTopicId: selectedSubTopicId,
      title,
      description,
      locked,
    }).then((res) => {
      const { subTopic } = res;
      console.log(subTopic, subTopic._id);
      mutate();
    });
    setSelectedSubTopicId(null);
  };

  const onDeleteSubTopicClick = (subTopicId) => {
    deleteSubTopic({ topicId: topic._id, subTopicId }).then(() => {
      mutate();
    });
  };

  const addSubTopic = (data) => {
    mutate();
  };

  const moveUp = (subTopicId) => {
    const currentPosition = topic.subTopics.findIndex(
      (subTopic) => subTopic._id === subTopicId
    );
    const subTopics = [...topic.subTopics];
    const subTopic = subTopics[currentPosition];
    const updatedSubTopics = subTopics.filter(
      (subTopic) => subTopic._id !== subTopicId
    );
    updatedSubTopics.splice(currentPosition - 1, 0, subTopic);
    mutate({
      ...topic,
      subTopics: updatedSubTopics,
    });

    // update database
    editSubTopicPosition({
      topicId: topic._id,
      subTopicId: subTopicId,
      position: currentPosition - 1,
    });
  };

  const moveDown = (subTopicId) => {
    const currentPosition = topic.subTopics.findIndex(
      (subTopic) => subTopic._id === subTopicId
    );
    const subTopics = [...topic.subTopics];
    const subTopic = subTopics[currentPosition];
    const updatedSubTopics = subTopics.filter(
      (subTopic) => subTopic._id !== subTopicId
    );
    updatedSubTopics.splice(currentPosition + 1, 0, subTopic);
    mutate({
      ...topic,
      subTopics: updatedSubTopics,
    });

    // update database
    editSubTopicPosition({
      topicId: topic._id,
      subTopicId: subTopicId,
      position: currentPosition + 1,
    });
  };

  return (
    <Box
      height="100%"
      width="auto"
      sx={(theme) => ({
        display: "flex",
        flexDirection: "row",
        overflow: "hidden",
        p: 0,
        pt: 1,
        pb: 1,
        [theme.breakpoints.down("md")]: {
          p: 0,
        },
      })}
    >
      <SubTopicForm
        open={subTopicFormOpen}
        color={topics[topic.title]?.primaryColor}
        subTopic={subTopics[selectedSubTopicId]}
        onSubmit={onEditSubTopicSubmit}
        handleClose={() => setSubTopicFormOpen(false)}
      />
      {/* <ContentTableDrawer />
      <Divider orientation="vertical" flexItem /> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "auto",
          pl: 5,
          pr: 5,
        }}
      >
        <Stack
          alignItems="center"
          spacing={2}
          sx={(theme) => ({
            ml: 5,
            mr: 5,
            pt: 1,
            pb: 1,
            [theme.breakpoints.down("md")]: {
              p: 1,
              m: 1,
            },
          })}
        >
          <TopicBigCard
            {...topics[topicId]}
            {...topic}
            onAddSubTopic={addSubTopic}
          />
          {isLoading ? (
            <>
              <Skeleton
                variant="rounded"
                width="100%"
                height={68}
                animation="wave"
              />
              <Skeleton
                variant="rounded"
                width="100%"
                height={68}
                animation={false}
              />
              <Skeleton variant="rounded" width="100%" height={68} />
              <Skeleton
                variant="rounded"
                width="100%"
                height={68}
                animation="wave"
              />
            </>
          ) : (
            topic?.subTopics.map((subTopic, index) => {
              const title = subTopic.title;
              const percentProgress =
                subTopic.videosCount === 0
                  ? 0
                  : ((progress[subTopic._id]?.videos || []).filter(
                      (video) => video.status === lockStatuses.FINISHED
                    ).length /
                      subTopic.videosCount) *
                    100;

              return (
                <Box
                  key={subTopic.title}
                  sx={{ display: "flex", width: "100%" }}
                >
                  {isSuperAdmin && (
                    <>
                      <IconButton
                        aria-label="move-up"
                        onClick={() => moveUp(subTopic._id)}
                        disabled={index === 0}
                      >
                        <ArrowUpwardIcon />
                      </IconButton>
                      <IconButton
                        aria-label="move-down"
                        onClick={() => moveDown(subTopic._id)}
                        disabled={index === Object.keys(subTopics).length - 1}
                      >
                        <ArrowDownwardIcon />
                      </IconButton>
                    </>
                  )}
                  <SubTopicCard
                    title={subTopic.title}
                    status={progress[subTopic._id]?.status}
                    primaryColor={topics[topic.title].primaryColor}
                    color={topics[topic.title].color}
                    openSubTopic={() => openSubTopic(title)}
                    editSubTopic={() => onEditSubTopicClick(subTopic._id)}
                    deleteSubTopic={() => onDeleteSubTopicClick(subTopic._id)}
                    progress={percentProgress}
                  />
                </Box>
              );
            })
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default withAuth(Topic);
