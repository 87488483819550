const styles = {
  topicCard: {
    margin: 1,
    minWidth: 280,
    height: 460,
    display: "flex",
    flexDirection: "column",
    borderRadius: 2,
  },
  well: {
    padding: 2,
    my: 2,
    borderRadius: 2,
    border: "2px solid #ddd",
    backgroundColor: "#f6f6f6",
  },
};
export default styles;
