import { useContext } from "react";
import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import AddSubTopic from "./AddSubTopic";
import { StoreContext } from "../App";
import { TakeMockButton } from "./utils/buttons";

export default function TopicBigCard({
  _id: topicId,
  title,
  description,
  thumbnail,
  primaryColor,
  color,
  onAddSubTopic,
}) {
  const {
    state: { isAuthenticated, user },
  } = useContext(StoreContext);

  const isSuperAdmin = isAuthenticated && user.role === "superadmin";

  return (
    <Card
      variant="outlined"
      sx={{
        margin: 1,
        width: "100%",
        mihHeight: 260,
        maxHeight: 280,
        display: "flex",
        flexDirection: "column",
        borderRadius: 2,
        backgroundColor: color,
      }}
    >
      <CardMedia component="img" height="160" src={thumbnail} alt="" />
      <CardContent
        sx={{
          backgroundColor: color,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          {isSuperAdmin && (
            <AddSubTopic
              topicId={topicId}
              color={primaryColor}
              onAdd={onAddSubTopic}
            />
          )}
          <TakeMockButton topicId={topicId} primaryColor={primaryColor} />
        </Box>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
}
