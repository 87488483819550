import MenuIcon from "@mui/icons-material/Menu";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import {
  AppBar,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { StoreContext } from "../App";
import { homeViews } from "../utils/constants";
import Logo from "./Logo";
import Profile from "./Profile";
import routes from "./routes";

export const MenuButton = ({ onClick }) => {
  return (
    <IconButton
      size="large"
      edge="start"
      color="inherit"
      aria-label="menu"
      sx={{ mr: 2 }}
      onClick={onClick}
    >
      <MenuIcon />
    </IconButton>
  );
};

const NavBar = React.forwardRef(({ toggleDrawer }, ref) => {
  const {
    state: { homeView },
    dispatch,
  } = useContext(StoreContext);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <AppBar ref={ref} position="fixed" backgroundColor={blue[100]}>
      <Toolbar>
        <MenuButton onClick={toggleDrawer} />
        <Logo />
        <ToggleButtonGroup
          value={homeView}
          exclusive
          size="small"
          onChange={(e, homeView) => {
            if (homeView !== null)
              dispatch({ type: "UPDATE_HOME_VIEW", payload: { homeView } });

            if (location.pathname !== routes.home.path) {
              navigate(routes.home.path);
            }
          }}
          sx={{ mr: 1 }}
        >
          <ToggleButton value={homeViews.PREPARATION_PLAN} aria-label="list">
            <ViewListIcon />
          </ToggleButton>
          <ToggleButton value={homeViews.TOPICS} aria-label="module">
            <ViewModuleIcon />
          </ToggleButton>
        </ToggleButtonGroup>
        <Profile />
      </Toolbar>
    </AppBar>
  );
});

export default NavBar;
