import {
  Box,
  Typography,
  FormControl,
  RadioGroup,
  CardContent,
  CardActions,
  Button,
  Card,
  TextField,
  FormControlLabel,
  Radio,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import { createQuestion, editQuestion } from "../api/content";
import { QuestionTypes } from "../utils/constants";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import SendIcon from "@mui/icons-material/Send";
import Editor from "./Editor";

export const AddQuestionButton = ({ subTopicId }) => {
  const [formMode, setFormMode] = useState(false);

  return formMode ? (
    <QuestionForm subTopicId={subTopicId} onCancel={() => setFormMode(false)} />
  ) : (
    <Button
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        p: 2,
        height: "auto",
      }}
      onClick={() => setFormMode(true)}
    >
      <AddIcon sx={{ height: 60, width: 60, color: "primary" }} />
      Add question
    </Button>
  );
};

const QuestionForm = ({ subTopicId, obj, onCancel }) => {
  const [text, setText] = useState("");
  const [options, setOptions] = useState([]);
  const [type, setType] = useState(QuestionTypes.MCQ);
  const [explanation, setExplanation] = useState("");

  useEffect(() => {
    if (obj) {
      setText(obj.text);
      setOptions(obj.options);
      setType(obj.type);
      setExplanation(obj.explanation);
    } else {
      addNewOption();
      addNewOption();
      addNewOption();
      addNewOption();
    }
  }, [obj]);

  const handleSubmit = async () => {
    if (obj?._id) {
      await editQuestion(obj._id, {
        text,
        options,
        explanation,
      });
    } else {
      await createQuestion({
        subTopicId,
        text,
        options,
        explanation,
        type,
      });
    }
    onCancel();
  };

  const updateOption = (index, text) => {
    if (options[index].text !== text) {
      const newOptions = [...options];
      newOptions[index].text = text;
      setOptions(newOptions);
    }
  };

  const onOptionSelect = (event) => {
    setOptions(
      options.map((opt, i) => {
        if (i === Number(event.target.value)) {
          return { ...opt, isCorrect: true };
        }
        return { ...opt, isCorrect: false };
      })
    );
  };

  const addNewOption = () => {
    setOptions([...options, { text: "", isCorrect: false }]);
  };
  const removeOption = (index) => {
    setOptions(options.filter((opt, i) => i !== index));
  };

  return (
    <Card
      variant="outlined"
      sx={{
        width: "95%",
        height: "auto",
        alignItems: "center",
        paddingTop: "1%",
        paddingLeft: "2.5%",
        paddingRight: "2.5%",
      }}
    >
      <CardContent>
        <Typography variant="h6">Add new question</Typography>
        <Box sx={{ marginBottom: 2 }}>
          {/* <TextField
            margin="dense"
            label="Question Text"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={text}
            onChange={(e) => setText(e.target.value)}
          /> */}
          <Editor
            placeholder="Enter question text here"
            value={text}
            onChange={setText}
          />
        </Box>
        <FormControl sx={{ marginBottom: 2 }}>
          <Typography variant="subtitle1">Options</Typography>

          <RadioGroup
            onChange={onOptionSelect}
            value={options.findIndex((opt) => opt.isCorrect)}
          >
            {options.map((opt, index) => (
              <Box sx={{ display: "flex" }} key={opt._id}>
                <Radio value={index} sx={{ borderRadius: 4 }} />

                <Editor
                  placeholder={`Option ${index + 1}`}
                  value={opt.text}
                  onChange={(value) => updateOption(index, value)}
                />

                <IconButton onClick={() => removeOption(index)}>
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </Box>
            ))}
          </RadioGroup>
          <Button onClick={addNewOption} startIcon={<AddIcon />}>
            Add New Option
          </Button>
        </FormControl>

        {/* <TextField
          margin="dense"
          label="Explanation"
          type="text"
          fullWidth
          multiline
          rows={4}
          value={explanation}
          onChange={(e) => setExplanation(e.target.value)}
        /> */}
        <Typography variant="subtitle1">Explanation:</Typography>
        <Editor
          placeholder="Explanation"
          value={explanation}
          onChange={setExplanation}
        />
      </CardContent>
      <CardActions>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          endIcon={<SendIcon />}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </CardActions>
    </Card>
  );
};

export default QuestionForm;
