import { delReq, getReq, postReq, putReq } from ".";

export const getNoticeBoardPosts = () => {
  return getReq("/noticeboard");
};

export const getNoticeBoardPostReplies = (postId) => {
  return getReq(`/noticeboard/${postId}/reply`);
};

export const createNoticeBoardPostReply = ({ postId, message }) => {
  return postReq(`/noticeboard/${postId}/reply`, { message });
};

export const updateNoticeBoardPostReply = ({ postId, replyId, message }) => {
  return putReq(`/noticeboard/${postId}/reply/${replyId}`, {
    message,
  });
};

export const deleteNoticeBoardPostReply = ({ postId, replyId }) => {
  return delReq(`/noticeboard/${postId}/reply/${replyId}`);
};
