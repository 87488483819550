import { Box, Divider, Fade, Paper, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { lockStatuses } from "../utils/constants";
import { TakeMockButton } from "./utils/buttons";
import { LockUnlockIcon } from "./utils/icons";

export default function TopicCard({
  id,
  title,
  description,
  thumbnail,
  color,
  primaryColor,
  secondaryColor,
  onLearn,
  status = lockStatuses.LOCKED,
}) {
  const [checked, setChecked] = React.useState(false);

  const handleMouseEnter = () => {
    setChecked(true);
  };
  const handleMouseLeft = () => {
    setChecked(false);
  };
  return (
    <Paper
      sx={{
        minWidth: 280,
        margin: 1,
        transition: "all 0.5s",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        zIndex: checked ? 5 : 0,
        "&:hover": {
          transform: "scale(1.1)",
          cursor: "pointer",
        },
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeft}
    >
      <Paper
        sx={{
          minWidth: 280,
          height: 324,
          // height: checked ? 460 : 324,
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <CardMedia
          component="img"
          src={thumbnail}
          alt={title}
          onClick={onLearn}
          sx={{
            transition: "all 1s",
            position: "relative",
            borderRadius: "inherit",
            minHeight: 260,
          }}
        />
        <Box
          onClick={onLearn}
          sx={{
            // height: checked ? 100 : 60,
            height: 60,
            padding: 2,
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Typography variant="h5" component="div">
              {title}
            </Typography>
            <Divider
              sx={{ ml: "auto", mr: 1 }}
              orientation="vertical"
              flexItem
            />
            <Tooltip
              title={
                status === lockStatuses.UNLOCKED ? (
                  <span>
                    You have unlocked this course.
                    <br />
                    Start learning now!
                  </span>
                ) : status === lockStatuses.FINISHED ? (
                  <span>
                    You have completed this course.
                    <br />
                    You can take the mock test now!
                  </span>
                ) : (
                  <span>
                    This course is locked.
                    <br />
                    Please complete the previous course to unlock this course.
                  </span>
                )
              }
            >
              <LockUnlockIcon status={status} />
            </Tooltip>
          </Box>
        </Box>
      </Paper>
      <Fade in={checked} style={{ transformOrigin: "50% 0" }}>
        <Paper
          sx={{
            position: "absolute",
            top: "99%",
            p: 2,
            pt: 0,
            zIndex: 15,
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            mb={2}
            onClick={onLearn}
          >
            {description}
          </Typography>

          <Box sx={{ marginTop: "auto" }}>
            <Button
              variant="outlined"
              size="small"
              sx={{ color: primaryColor, mr: 1 }}
              onClick={onLearn}
            >
              Learn
            </Button>
            <TakeMockButton
              hideBorder
              topicTitle={title}
              topicId={id}
              primaryColor={secondaryColor}
            />
          </Box>
        </Paper>
      </Fade>
    </Paper>
  );
}
