import { useEffect, useState } from "react";
import { List, ListItem, Paper, Typography } from "@mui/material";
import NoticeBoardPostCard from "../components/NoticeBoardPostCard";
import { getNoticeBoardPosts } from "../api/noticeboard";
import { Box } from "@mui/system";
import Loading from "../components/utils/Loading";
import withAuth from "../hoc/withAuth";

const NoticeBoard = ({ user }) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPosts();
  }, []);

  const getPosts = async () => {
    try {
      setLoading(true);
      const res = await getNoticeBoardPosts();
      setPosts(res.noticeboard);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        width: "60%",
        margin: "12px auto",
        overflow: "scroll",
      }}
    >
      <Typography variant="h5">Notice Board</Typography>

      {loading ? (
        <Loading />
      ) : (
        <List>
          {posts.length > 0 ? (
            posts.map((post) => {
              return (
                <ListItem key={post._id}>
                  <NoticeBoardPostCard post={post} userId={user?._id} />
                </ListItem>
              );
            })
          ) : (
            <Paper
              sx={{
                width: "auto",
                height: 300,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              variant="outlined"
            >
              <Typography variant="body1">No posts yet</Typography>
            </Paper>
          )}
        </List>
      )}
    </Box>
  );
};

export default withAuth(NoticeBoard);
