import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import { useContext, useRef, useState } from "react";
import Latex from "react-latex";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { StoreContext } from "../App";

const Formula = ({ obj, editFormula, deleteFormula }) => {
  const {
    state: { isAuthenticated, user },
  } = useContext(StoreContext);

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const showMenu = () => {
    setOpen(true);
  };
  const hideMenu = () => {
    setOpen(false);
  };

  const handleEdit = () => {
    editFormula();
    hideMenu();
  };

  const isSuperAdmin = isAuthenticated && user.role === "superadmin";

  return (
    <>
      <Paper
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "auto",
          flex: 1,
          p: 2,
          height: "auto",
          position: "relative",
        }}
      >
        <Typography variant="body1" gutterBottom>
          {obj.title}
        </Typography>
        <Box sx={{ m: 2 }}>
          <Latex>{`$${obj.formula}$`}</Latex>
        </Box>
        <Typography variant="body2" sx={{ mt: "auto" }}>
          {obj.description}
        </Typography>
        {isSuperAdmin && (
          <>
            <IconButton
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={showMenu}
              ref={anchorRef}
              sx={{
                position: "absolute",
                right: 1,
                top: 3,
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorRef?.current}
              open={open}
              onClose={hideMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleEdit}>Edit</MenuItem>
              <MenuItem onClick={deleteFormula}>Delete</MenuItem>
            </Menu>
          </>
        )}
      </Paper>
    </>
  );
};

export default Formula;
