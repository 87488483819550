import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { createTest, getTestProfile } from "../api/test";
import { Row } from "../components/utils/Div";
import { testLink } from "../utils/constants";
import { convertToMap } from "../utils/object";

const TestProfile = () => {
  const { id: testId } = useParams();
  const [loading, setLoading] = useState(true);
  const [testProfile, setTestProfile] = useState({});
  const [topics, setTopics] = useState({});
  const [subTopics, setSubTopics] = useState({});

  const onStart = async () => {
    // TODO: move this to backend
    const testObj = {
      title: testProfile.title,
      sections: testProfile.sections,
      startTime: dayjs().add(2, "seconds").toISOString(),
      allowSectionSwitching: testProfile.allowSectionSwitching,
      testType: testProfile.testType,
    };
    const res = await createTest(testObj);
    window.open(testLink(res.test._id), "_blank").focus();
  };

  const init = async () => {
    try {
      const res = await getTestProfile(testId);
      const { testProfile, subTopics, topics } = res;
      setTestProfile(testProfile);
      setTopics(convertToMap(topics, "_id"));
      setSubTopics(convertToMap(subTopics, "_id"));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return loading ? (
    <CircularProgress />
  ) : (
    <Box
      sx={{
        width: "80%",
        flex: 1,
        my: 1,
        display: "flex",
        marginLeft: "auto",
        marginRight: "auto",
        flexDirection: "column",
      }}
    >
      <Row>
        <Typography variant="h4" component="div">
          {testProfile.title}
        </Typography>
        <Button variant="contained" onClick={onStart}>
          Start Test
        </Button>
      </Row>

      {testProfile.sections.map((section, index) => (
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "fit-content",
            height: "auto",
            overflow: "visible",
            m: 1,
          }}
          variant="outlined"
        >
          <CardContent sx={{ pt: 1 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                pt: 0,
              }}
            >
              <Typography variant="h6" fontWeight="400" component="div">
                Section: {section.title}
              </Typography>
            </Box>
            <Divider sx={{ my: 1 }} />
            <Typography variant="body1" gutterBottom>
              <b>{section.numOfQuestions}</b> questions
            </Typography>
            <Typography variant="body1" gutterBottom>
              <b>{section.duration}</b> minutes
            </Typography>

            {section.subTopicIds?.length ? (
              <>
                <Typography variant="body1" component="div">
                  Sub-topics:
                </Typography>
                <ul>
                  {section.subTopicIds.map((subTopicId) => (
                    <li key={subTopicId}>{subTopics[subTopicId]?.title}</li>
                  ))}
                </ul>
              </>
            ) : (
              <>
                <Typography variant="body1" component="div">
                  Topics:
                </Typography>
                <ul>
                  {section.topicIds.map((topicId) => (
                    <li key={topicId}>{topics[topicId]?.title}</li>
                  ))}
                </ul>
              </>
            )}
          </CardContent>
        </Card>
      ))}
      <Button variant="contained" onClick={onStart}>
        Start Test
      </Button>
    </Box>
  );
};

export default TestProfile;
