import { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { createFormula } from "../api/content";

export const FormulaForm = ({ open, obj, onSubmit, handleClose }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [formula, setFormula] = useState("");

  useEffect(() => {
    if (obj) {
      setTitle(obj.title);
      setDescription(obj.description);
      setFormula(obj.formula);
    }
  }, [obj]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{obj ? "Edit" : "Add"} Formula</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {obj ? "Edit" : "Add"} a formula for this subtopic.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="title"
          label="Title"
          type="text"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          margin="dense"
          id="formula"
          label="Formula"
          type="text"
          fullWidth
          value={formula}
          onChange={(e) => setFormula(e.target.value)}
        />
        <TextField
          margin="dense"
          id="description"
          label="Description"
          type="text"
          fullWidth
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onSubmit({ title, description, formula })}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AddFormula = ({ subTopicId, onAdd }) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAdd = (data) => {
    createFormula({ subTopicId, ...data }).then((res) => {
      onAdd?.(res.formula);
      handleClose();
    });
  };

  return (
    <>
      <FormulaForm open={open} onSubmit={handleAdd} handleClose={handleClose} />
      <Button
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          p: 2,
          height: "auto",
        }}
        onClick={handleClickOpen}
      >
        <AddIcon sx={{ height: 60, width: 60, color: "primary" }} />
        Add new formula
      </Button>
    </>
  );
};

export default AddFormula;
