import axios from "axios";
import { redirect } from "./utils";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_SM_BACKEND_URL;

export const postReq = async (url, body, auth) => {
  const headers = { "Content-Type": "application/json" };

  try {
    let response = await axios.post(url, body, { headers });
    return response.data;
  } catch (error) {
    if (error.response?.data?.redirectUrl) {
      console.log(error.response.data);
      redirect(error.response.data.redirectUrl);
    } else {
      throw error;
    }
  }
};

export const postFileReq = async (url, file, body) => {
  const headers = { "Content-Type": "multipart/form-data" };
  const formData = new FormData();
  formData.append("file", file);
  for (let key in body) {
    formData.append(key, body[key]);
  }
  try {
    let response = await axios.post(url, formData, { headers });
    return response.data;
  } catch (error) {
    if (error.response?.data?.redirectUrl) {
      console.log(error.response.data);
      redirect(error.response.data.redirectUrl);
    } else {
      throw error;
    }
  }
};

export const getReq = async (url, params, auth) => {
  const headers = { "Content-Type": "application/json" };
  const token = localStorage.getItem("token");
  // const params = new URLSearchParams(queryParams);
  if (auth) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  try {
    let response = await axios.get(url, { params, headers });
    return response.data;
  } catch (error) {
    if (error.response?.data?.redirectUrl) {
      console.log(error.response.data);
      redirect(error.response.data.redirectUrl);
    } else {
      throw error;
    }
  }
};

export const delReq = async (url, auth) => {
  const headers = { "Content-Type": "application/json" };
  const token = localStorage.getItem("token");
  if (auth) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  try {
    let response = await axios.delete(url, { headers });
    return response.data;
  } catch (error) {
    if (error.response?.data?.redirectUrl) {
      console.log(error.response.data);
      redirect(error.response.data.redirectUrl);
    } else {
      throw error;
    }
  }
};

export const putReq = async (url, body, auth) => {
  const headers = { "Content-Type": "application/json" };
  const token = localStorage.getItem("token");
  if (auth) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  try {
    let response = await axios.put(url, body, { headers });
    return response.data;
  } catch (error) {
    if (error.response?.data?.redirectUrl) {
      console.log(error.response.data);
      redirect(error.response.data.redirectUrl);
    } else {
      throw error;
    }
  }
};
