import { Link } from "react-router-dom";

const XLink = ({ to, external = false, children }) => {
  if (external) {
    return (
      <a
        href={to + "?returnTo=" + window.location.href}
        target="_blank"
        rel="noreferrer"
      >
        {children}
      </a>
    );
  }
  return <Link to={to}>{children}</Link>;
};

export default XLink;
