import { useState } from "react";
import dayjs from "dayjs";
import { Button, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Popup from ".";

const CreateTestFromProfilePopup = ({ open, onSubmit, onCancel }) => {
  const [startTime, setStartTime] = useState(dayjs(new Date()).add(1, "day"));

  const onStartTimeChange = (value) => {
    setStartTime(value);
  };
  const onSubmitClick = () => {
    onSubmit({ startTime: startTime.toISOString() });
  };

  const actions = [<Button onClick={onSubmitClick}>Create</Button>];
  const form = (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        label="Start time"
        value={startTime}
        onChange={onStartTimeChange}
        renderInput={(params) => <TextField sx={{ m: 1 }} {...params} />}
      />
    </LocalizationProvider>
  );
  return (
    <Popup
      open={open}
      title={"Create Test from profile"}
      actions={actions}
      content={form}
      onClose={onCancel}
    />
  );
};

export default CreateTestFromProfilePopup;
