import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import withAuth from "../hoc/withAuth";
import {
  Cancel as CancelIcon,
  Check as CheckIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import companiesData from "../data/companies";

const CompanyBigCard = ({
  _id: companyId,
  title,
  color,
  isSuperAdmin,
  thumbnail,
  editMode,
  handleEdit,
  handleCancel,
  handleUpdate,
}) => {
  const companyData = companiesData[title?.toLowerCase()];

  return !companyId ? null : (
    <Card
      variant="outlined"
      sx={{
        mb: 2,
        mt: 2,
        width: "100%",
        mihHeight: 260,
        maxHeight: 280,
        display: "flex",
        flexDirection: "column",
        borderRadius: 2,
        backgroundColor: color,
      }}
    >
      <CardMedia
        component="img"
        height="160"
        sx={{
          padding: 2,
          width: "auto",
          objectFit: "contain",
          backgroundColor: "#fff",
          borderBottomRadius: 2,
          borderBottomWidth: 2,
        }}
        src={companyData.thumbnail}
        alt=""
      />
      <CardContent
        sx={{
          backgroundColor: companyData.color,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5" component="div">
            {title}
          </Typography>
          {isSuperAdmin &&
            (!editMode ? (
              <Button startIcon={<EditIcon />} onClick={handleEdit}>
                Edit
              </Button>
            ) : (
              <div>
                <Button
                  variant="outlined"
                  startIcon={<CancelIcon />}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  sx={{ ml: 2 }}
                  startIcon={<CheckIcon />}
                  onClick={handleUpdate}
                >
                  Update
                </Button>
              </div>
            ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default withAuth(CompanyBigCard);
