import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useEffect, useState } from "react";
import Popup from ".";
import { getTopicDetails, useTopics } from "../../api/content";

const SelectTopicsForCompanyPopup = ({
  open,
  initSelectedSubTopics,
  onUpdate,
  handleClose,
  topic: cTopic,
}) => {
  const {
    data: { topics },
    mutate,
  } = useTopics();
  const [selectedSubTopics, setSelectedSubTopics] = useState(
    new Set(initSelectedSubTopics)
  );

  useEffect(() => {
    setSelectedSubTopics(new Set(initSelectedSubTopics));
  }, [initSelectedSubTopics]);

  const getSubTopicsOfTopic = (topic) => {
    if (topic.subTopics) return;
    getTopicDetails(topic.title).then((res) => {
      const { topic } = res;
      const newTopics = [...topics];
      const index = newTopics.findIndex((t) => t._id === topic._id);
      newTopics[index] = topic;
      mutate(newTopics);
    });
  };

  const updateSelectedSubTopics = (newSubTopics) => {
    setSelectedSubTopics(new Set([...selectedSubTopics, ...newSubTopics]));
  };

  const selectedSubTopicIds = new Set(
    [...selectedSubTopics].map((st) => st._id)
  );

  return !open ? null : (
    <Popup
      open={open}
      onClose={handleClose}
      title={`Select subtopics for topic ${cTopic.title}`}
      actions={[
        <Button onClick={handleClose}>Cancel</Button>,
        <Button onClick={() => onUpdate(selectedSubTopics)}>Save</Button>,
      ]}
      content={topics.map((topic) => (
        <FormControl key={topic._id} sx={{ m: 1, width: 300 }}>
          <InputLabel id={topic.id}>{topic.title}</InputLabel>
          <Select
            labelId={topic.id}
            multiple
            value={
              topic.subTopics?.filter((st) =>
                selectedSubTopicIds.has(st._id)
              ) || []
            }
            onChange={(e) => updateSelectedSubTopics(e.target.value)}
            input={<OutlinedInput label="Chip" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((subTopic) => (
                  <Chip key={subTopic._id} label={subTopic.title} />
                ))}
              </Box>
            )}
            onOpen={(e) => getSubTopicsOfTopic(topic)}
          >
            {topic.subTopics?.map((subTopic) => (
              <MenuItem key={subTopic._id} value={subTopic}>
                {subTopic.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ))}
    />
  );
};

export default SelectTopicsForCompanyPopup;
