import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import routes from "../layout/routes";
import { TestSectionsTimeline } from "./TestCard";

const TestProfileCard = ({ obj, onCreateTest, onDelete, onExpand }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef(null);

  return (
    <>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "fit-content",
          height: "auto",
          overflow: "visible",
          m: 1,
        }}
        variant="outlined"
      >
        <CardContent sx={{ height: 260, pt: 1 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 0,
            }}
          >
            <Typography variant="h6" component="div">
              <Link to={routes.testProfile.getPath(obj._id)}>{obj.title}</Link>
            </Typography>
            <IconButton
              ref={anchorRef}
              aria-label="more"
              id="long-button"
              aria-controls={menuOpen ? "long-menu" : undefined}
              aria-expanded={menuOpen ? "true" : undefined}
              aria-haspopup="true"
              onClick={() => setMenuOpen(true)}
              sx={{ mr: -1 }}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
          <Divider />
          <Typography
            sx={{ mt: 1 }}
            variant="subtitle1"
            gutterBottom
            component="div"
          >
            Sections:
          </Typography>
          <TestSectionsTimeline obj={obj} compressed onViewAll={onExpand} />
        </CardContent>
      </Card>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorRef?.current}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        <MenuItem onClick={onCreateTest}>
          <ListItemIcon>
            <AddIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Create test</ListItemText>
        </MenuItem>
        <MenuItem onClick={onDelete}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default TestProfileCard;
