export const testStatuses = {
  SCHEDULED: "SCHEDULED",
  IN_PROGRESS: "IN_PROGRESS",
  NOT_STARTED: "NOT_STARTED",
  FINISHED: "FINISHED",
  SUBMITTED: "SUBMITTED",
};

export const testLink = (id) => process.env.REACT_APP_TEST_WEB + "/test/" + id;
export const testReportLink = (id) =>
  process.env.REACT_APP_TEST_WEB + "/test/report/" + id;

export const plansLink = `${process.env.REACT_APP_AUTH_WEB}/plans`;

export const QuestionTypes = {
  MCQ: "mcq",
  Code: "code",
};

export const lockStatuses = {
  LOCKED: "LOCKED",
  UNLOCKED: "UNLOCKED",
  FINISHED: "FINISHED",
};

export const homeViews = {
  TOPICS: "TOPICS",
  PREPARATION_PLAN: "PREPARATION_PLAN",
};

export const palette = {
  navbar: {
    background: "#F5E000",
    color: "#000",
  },
  background: "#F4F6F3",
};

export const VIDEO_PROGRESS_CUTOFF = 0.9;

export const ERROR_CODES = {
  NO_SUBSCRIPTION: "NO_SUBSCRIPTION",
  FEATURE_NOT_ENABLED: "FEATURE_NOT_ENABLED",
};

export const FEATURES = {
  JOB_POSTINGS: "JOB_POSTINGS",
  VIEW_CONTENT: "VIEW_CONTENT",
  WATCH_VIDEOS: "WATCH_VIDEOS",
  PRACTICE_TESTS: "PRACTICE_TESTS",
  ENGLISH_TRAINING: "ENGLISH_TRAINING",
  ENGLISH_BOT: "ENGLISH_BOT",
  DOUBT_SUPPORT: "DOUBT_SUPPORT",
  MOCK_INTERVIEWS: "MOCK_INTERVIEWS",
  PLACEMENT_ASSISTANCE: "PLACEMENT_ASSISTANCE",
};

export const PREPARATION_PLAN_MODES = {
  STUDY: "study",
  PRACTICE: "practice",
  REVISION: "revision",
};

export const PREPARATION_PRACTICE_TEST_PROGRESS_CUTOFF = 0.6;

export const testTypes = [
  { name: "Practice", value: "PRACTICE" },
  // { name: "Preparation practice", value: "PREPARATION_PRACTICE" },
  // { name: "College", value: "COLLEGE" },
  // { name: "Company", value: "COMPANY" },
  // { name: "Company practice", value: "COMPANY_PRACTICE" },
  { name: "Scholarship", value: "SCHOLARSHIP" },
  { name: "Grand Test", value: "GRAND_TEST" },
];
