import { delReq, getReq, postReq } from ".";

export const createTest = (test) => {
  // console.log(test);
  return postReq(`/test`, { test }, true);
};

export const createPracticeTest = (topicId, subTopicId) => {
  return postReq(`/practice`, { topicId, subTopicId }, true);
};

export const createPreparationPracticeTest = (test) => {
  return postReq(`/practice/preparation`, { test }, true);
};

export const createPracticeTestOfCompany = (companyId) => {
  return postReq(`/practice/company`, { companyId }, true);
};

export const getTestProfile = (testProfileId) => {
  return getReq(`/testprofile/${testProfileId}`, {}, true);
};

export const getTests = () => {
  return getReq(`/test`, {}, true);
};

export const deleteTest = (testId) => {
  return delReq(`/test/${testId}`, true);
};

export const createTestProfile = (testProfile) => {
  // console.log(test);
  return postReq(`/testprofile`, { testProfile }, true);
};

export const getTestProfiles = () => {
  return getReq(`/testprofile`, {}, true);
};

export const deleteTestProfile = (testProfileId) => {
  return delReq(`/testprofile/${testProfileId}`, {});
};
