import { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getTests } from "../api/test";
import { convertToMap } from "../utils/object";
import TestCard, {
  TestCardActions,
  TestSectionsTimeline,
} from "../components/TestCard";
import { deleteTest } from "../api/test";
import assets from "../utils/assets";
import { Box } from "@mui/system";
import dayjs from "dayjs";

const TestList = () => {
  const [tests, setTests] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedTest, setSelectedTest] = useState(null);

  useEffect(() => {
    getTests().then((res) => {
      const { tests } = res.data;
      setTests(convertToMap(tests, "_id"));
    });
  }, []);

  const onDelete = async (testId) => {
    try {
      await deleteTest(testId);
      setTests((tests) => {
        const newTests = { ...tests };
        delete newTests[testId];
        return newTests;
      });
    } catch (error) {
      console.log(error);
    }
  };

  const showTest = (testId) => {
    setSelectedTest(testId);
    setDialogOpen(true);
  };

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        scroll={"paper"}
        maxWidth={"md"}
        fullWidth
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 1,
            pl: 3,
          }}
        >
          {tests[selectedTest]?.title}
          <IconButton aria-label="close" onClick={() => setDialogOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          dividers={true}
          sx={{
            height: "60vh",
          }}
        >
          <Typography
            sx={{ mt: 1 }}
            variant="subtitle1"
            gutterBottom
            component="div"
          >
            Sections:
          </Typography>
          <TestSectionsTimeline obj={tests[selectedTest]} />
        </DialogContent>
        <DialogActions>
          <TestCardActions obj={tests[selectedTest]} />
        </DialogActions>
      </Dialog>
      <Box
        height="100%"
        width="100%"
        sx={{
          overflow: "auto",
          pt: 1,
          pb: 1,
        }}
      >
        <Box
          sx={{
            width: "80%",
            display: "flex",
            flexDirection: "column",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Card
            variant="outlined"
            sx={{
              margin: 1,
              width: "auto",
              mihHeight: 260,
              maxHeight: 280,
              display: "flex",
              flexDirection: "column",
              borderRadius: 2,
            }}
          >
            <CardMedia component="img" height="160" src={assets.test} alt="" />
            <CardContent sx={{}}>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{ fontWeight: "bold" }}
              >
                Tests
              </Typography>
              <Typography variant="body2" color="text.secondary"></Typography>
            </CardContent>
          </Card>
          <Grid
            container
            spacing={0}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            {Object.values(tests)
              ?.sort((a, b) =>
                dayjs(a.startTime) < dayjs(b.startTime) ? 1 : -1
              )
              .map((t) => (
                <Grid item key={t._id} xs={12} s={6} md={6} lg={4} xl={3}>
                  <TestCard
                    obj={t}
                    onDelete={() => onDelete(t._id)}
                    onExpand={() => showTest(t._id)}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default TestList;
