import CompanyDetail from "../pages/CompanyDetail";
import CreateTest from "../pages/CreateTest";
import CreateTestProfile from "../pages/CreateTestProfile";
import Home from "../pages/Home";
import NoticeBoard from "../pages/NoticeBoard";
import SubTopicDetail from "../pages/SubTopicDetail";
import SubTopicVideos from "../pages/SubTopicVideos";
import Tests from "../pages/TestList";
import TestProfile from "../pages/TestProfile";
import TestProfiles from "../pages/TestProfilesList";
import TopicDetail from "../pages/TopicDetail";

const routes = {
  home: {
    title: "Home",
    path: "/",
    component: <Home />,
    sidebar: true,
  },
  companyDetail: {
    title: "Company Details",
    _path: "/company",
    get path() {
      return `${this._path}/:companyId`;
    },
    getPath: function (id) {
      return `${this._path}/${id}`;
    },
    component: <CompanyDetail />,
  },
  topicDetail: {
    title: "Topic Details",
    _path: "/topic",
    get path() {
      return `${this._path}/:topicId`;
    },
    getPath: function (id) {
      return `${this._path}/${id}`;
    },
    component: <TopicDetail />,
  },
  subTopicDetail: {
    title: "Sub Topic",
    get path() {
      return this.getPath();
    },
    getPath: function (topicId = ":topicId", subTopicId = ":subTopicId") {
      return `/topic/${topicId}/subTopic/${subTopicId}`;
    },
    component: <SubTopicDetail />,
  },
  subTopicVideo: {
    title: "Video",
    get path() {
      return this.getPath();
    },
    getPath: function (
      topicId = ":topicId",
      subTopicId = ":subTopicId",
      videoId = ":videoId"
    ) {
      return `/topic/${topicId}/subTopic/${subTopicId}/video/${videoId}`;
    },
    component: <SubTopicVideos />,
  },
  tests: {
    title: "Tests",
    path: "/test/list",
    component: <Tests />,
  },
  createTest: {
    title: "Create Test",
    path: "/test/create",
    component: <CreateTest />,
  },
  takeTest: {
    title: "Take Test",
    path: "/test/:id",
    component: () => <></>,
  },
  testProfile: {
    title: "Test Profiles",
    _path: "/test-profile",
    get path() {
      return `${this._path}/:id`;
    },
    getPath(id) {
      return `${this._path}/${id}`;
    },
    component: <TestProfile />,
    sidebar: false,
  },
  testProfiles: {
    title: "Test Profiles",
    path: "/test-profiles",
    component: <TestProfiles />,
    sidebar: false,
  },
  createTestProfile: {
    title: "Create Test Profile",
    path: "/test-profiles/create",
    component: <CreateTestProfile />,
    sidebar: false,
  },
  noticeboard: {
    title: "Notice Board",
    path: "/noticeboard",
    component: <NoticeBoard />,
    sidebar: true,
  },
};

export default routes;
