import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import { Box, FormControlLabel, LinearProgress, Paper } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { StoreContext } from "../App";
import { getCompleteProgress } from "../api/progress";
import subjects, { topicGroups } from "../data/subjects";
import NavBar from "./NavBar";
import Router from "./Router";
import Sidebar from "./Sidebar";

const LinearProgressX = ({ value, color }) => {
  return (
    <LinearProgress
      variant="determinate"
      value={value}
      color={color}
      sx={{ flex: 1 }}
    />
  );
};

const PROGRESS_LABEL_STATUS = {
  FINISHED: "FINISHED",
  IN_PROGRESS: "IN_PROGRESS",
  NOT_STARTED: "NOT_STARTED",
};

const PROGRESS_FINISH_CUTOFF = 95;

const ProgressLabel = ({
  title,
  status = PROGRESS_LABEL_STATUS.NOT_STARTED,
}) => {
  return (
    <FormControlLabel
      value="bottom"
      control={
        status === PROGRESS_LABEL_STATUS.FINISHED ? (
          <CheckCircleIcon color="success" />
        ) : status === PROGRESS_LABEL_STATUS.IN_PROGRESS ? (
          <PendingOutlinedIcon color="primary" />
        ) : (
          <CircleOutlinedIcon color="action" />
        )
      }
      label={title}
      labelPlacement="bottom"
    />
  );
};

export default function Layout() {
  const [height, setHeight] = useState(0);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const toggleDrawer = () => setSidebarOpen(!sidebarOpen);

  const ref = useRef();
  const [progress, setProgress] = useState({});
  const {
    state: { user },
  } = useContext(StoreContext);

  useEffect(() => {
    setHeight(ref.current?.offsetHeight);
  }, [ref.current?.offsetHeight]);

  useEffect(() => {
    if (user?._id) {
      getCompleteProgress().then((res) => {
        console.log(res.progress);

        const progress = res.progress.reduce((acc, topicProgress) => {
          const subject = subjects[topicProgress.title];
          const key = subject.group;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(topicProgress.progress);
          return acc;
        }, {});

        console.log(progress);
        setProgress(
          Object.keys(progress).reduce((acc, key) => {
            const total = progress[key].length;
            const completed = progress[key].reduce((p, acc) => p + acc, 0);
            acc[key] = total > 0 ? completed / total : 0;
            return acc;
          }, {})
        );
      });
    }
  }, [user]);

  return (
    <Box>
      <NavBar ref={ref} toggleDrawer={toggleDrawer} />
      <Sidebar open={sidebarOpen} toggleDrawer={toggleDrawer} />
      <Box sx={{ width: "100%", overflowY: "scroll", mt: `${height}px` }}>
        <Paper
          variant="outlined"
          sx={{
            display: "flex",
            flexDirection: "row",
            mt: 2,
            mx: 2,
            py: 1,
            alignItems: "center",
            overflow: "auto",
          }}
        >
          <ProgressLabel
            title="Onboarding"
            status={
              (user?.emailVerified || user?.mobileVerified) &&
              PROGRESS_LABEL_STATUS.FINISHED
            }
          />
          <LinearProgressX
            value={progress[topicGroups.analytics]}
            color="primary"
          />
          <ProgressLabel
            title="Analytical + Verbal"
            status={
              progress[topicGroups.analytics] > PROGRESS_FINISH_CUTOFF
                ? PROGRESS_LABEL_STATUS.FINISHED
                : PROGRESS_LABEL_STATUS.IN_PROGRESS
            }
          />
          <LinearProgressX
            value={progress[topicGroups.programming]}
            color="inherit"
          />
          <ProgressLabel
            title="Programming"
            status={
              progress[topicGroups.programming] > PROGRESS_FINISH_CUTOFF
                ? PROGRESS_LABEL_STATUS.FINISHED
                : progress[topicGroups.analytics] > PROGRESS_FINISH_CUTOFF
                ? PROGRESS_LABEL_STATUS.IN_PROGRESS
                : PROGRESS_LABEL_STATUS.NOT_STARTED
            }
          />
          <LinearProgressX value={0} color="inherit" />
          <ProgressLabel title="Project" />
          <LinearProgressX value={0} color="inherit" />
          <ProgressLabel title="Placement" />
        </Paper>
        <Router />
      </Box>
    </Box>
  );
}
