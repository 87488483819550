export const updateProgressInLocal = ({
  subTopicId,
  videoId,
  playedSeconds,
  playedFraction,
}) => {
  const progress = JSON.parse(localStorage.getItem(`progress`) || "{}");
  localStorage.setItem(
    "progress",
    JSON.stringify({
      ...progress,
      [subTopicId]: {
        ...(progress[subTopicId] || {}),
        [videoId]: {
          playedSeconds,
          playedFraction,
        },
      },
    })
  );
};
