import { useMemo } from "react";
import useSWR from "swr";
import { delReq, getReq, postFileReq, postReq, putReq } from ".";
import { convertToMap } from "../utils/object";

export const useTopics = () => {
  const {
    data = { topics: [], progress: [] },
    mutate,
    isLoading,
    error,
  } = useSWR(`/content/topic`, getReq);

  const progress = useMemo(
    () => convertToMap(data.progress, "topicId"),
    [data.progress]
  );

  return {
    data: { ...data, progress },
    isLoading,
    error,
    mutate: (topics, progress = []) => mutate({ topics, progress }),
  };
};

export const useTopicDetails = (topicId) => {
  const {
    data = {
      topic: {},
      progress: [],
    },
    mutate,
    isLoading,
    error,
  } = useSWR(
    {
      url: `/content/topic`,
      args: { topic: topicId },
    },
    ({ url, args }) => getReq(url, args)
  );

  const progress = convertToMap(data.progress, "subTopicId");
  const subTopics = convertToMap(data.topic.subTopics, "_id");

  return {
    data: { ...data, progress, subTopics },
    isLoading,
    error,
    mutate: (topic) => mutate({ topic }),
  };
};

export const getTopicDetails = (topic) => {
  return getReq(`/content/topic`, { topic }, true);
};

export const createSubTopic = ({ topicId, title, description, locked }) => {
  return postReq(
    `/content/subTopic`,
    { topicId, title, description, locked },
    true
  );
};

export const editSubTopic = ({ subTopicId, title, description, locked }) => {
  return putReq(
    `/content/subTopic`,
    { subTopicId, title, description, locked },
    true
  );
};

export const editSubTopicPosition = ({ topicId, subTopicId, position }) => {
  return putReq(
    `/content/subTopicPosition`,
    { topicId, subTopicId, position },
    true
  );
};

export const deleteSubTopic = ({ topicId, subTopicId }) => {
  return delReq(`/content/topic/${topicId}/subTopic/${subTopicId}`, true);
};

export const useSubTopicDetails = (topicId, subTopicId) => {
  const {
    data = {
      subTopic: {},
      progress: { videos: [] },
    },
    mutate,
    isLoading,
    error,
  } = useSWR(
    {
      url: `/content/subTopic`,
      args: { topic: topicId, subTopic: subTopicId },
    },
    ({ url, args }) => getReq(url, args)
  );

  const progress = convertToMap(data.progress?.videos, "videoId");

  return {
    data: { ...data, progress },
    isLoading,
    error,
    mutateSubTopic: (subTopic) => mutate({ subTopic }),
    mutate,
  };
};

export const getVideoDetails = ({ subTopicId, videoId }) => {
  return getReq(`/content/subTopic/${subTopicId}/video/${videoId}`, {}, true);
};

export const getVideoDiscussions = (videoId) => {
  return getReq(`/discussion/video`, { videoId }, true);
};

export const createVideoDiscussionQuestion = ({ videoId, text }) => {
  return postReq(`/discussion/video/question`, { text, videoId }, true);
};

export const createVideoDiscussionAnswer = ({ videoId, text, questionId }) => {
  return postReq(
    `/discussion/video/answer`,
    { text, videoId, questionId },
    true
  );
};

export const updateVideoDiscussionQuestion = ({
  videoId,
  discussionId,
  text,
}) => {
  return putReq(
    `/discussion/video/question`,
    { text, discussionId, videoId },
    true
  );
};

export const updateVideoDiscussionAnswer = ({
  videoId,
  text,
  questionId,
  answerId,
}) => {
  return putReq(
    `/discussion/video/answer`,
    { text, videoId, questionId, answerId },
    true
  );
};

export const deleteVideoDiscussionQuestion = ({ videoId, discussionId }) => {
  return delReq(`/discussion/video/${videoId}/${discussionId}/question`, true);
};

export const deleteVideoDiscussionAnswer = ({
  videoId,
  questionId,
  answerId,
}) => {
  return delReq(
    `/discussion/video/${videoId}/${questionId}/answer/${answerId}`,
    true
  );
};

export const voteDiscussionQuestion = ({ videoId, text, questionId, vote }) => {
  return postReq(
    `/discussion/video/question/vote`,
    { text, videoId, questionId, vote },
    true
  );
};

export const voteDiscussionAnswer = ({
  videoId,
  text,
  questionId,
  answerId,
  vote,
}) => {
  return postReq(
    `/discussion/video/answer/vote`,
    { text, videoId, questionId, answerId, vote },
    true
  );
};

export const createFormula = ({ subTopicId, title, description, formula }) => {
  return postReq(
    `/content/subtopic/formula`,
    { subTopicId, title, description, formula },
    true
  );
};

export const editFormula = ({ formulaId, title, description, formula }) => {
  return putReq(
    `/content/subtopic/formula`,
    { formulaId, title, description, formula },
    true
  );
};

export const deleteFormula = (formulaId) => {
  return delReq(`/content/subtopic/formula/${formulaId}`, true);
};

export const createArticle = ({ subTopicId, title, content }) => {
  return postReq(
    `/content/subtopic/article`,
    { subTopicId, title, content },
    true
  );
};

export const editArticle = ({ articleId, title, content }) => {
  return putReq(
    `/content/subtopic/article`,
    { articleId, title, content },
    true
  );
};

export const deleteArticle = (articleId) => {
  return delReq(`/content/subtopic/article/${articleId}`, true);
};

export const createVideo = ({
  subTopicId,
  title,
  description,
  languages,
  locked,
}) => {
  return postReq(
    `/content/subtopic/video`,
    { subTopicId, title, description, languages, locked },
    true
  );
};

export const editVideo = ({
  videoId,
  title,
  description,
  languages,
  position,
  locked,
}) => {
  return putReq(
    `/content/subtopic/video`,
    { videoId, title, description, languages, position, locked },
    true
  );
};

export const editVideoPosition = ({ videoId, subTopicId, position }) => {
  return putReq(
    `/content/subtopic/videoPosition`,
    { videoId, subTopicId, position },
    true
  );
};

export const deleteVideo = (videoId) => {
  return delReq(`/content/subtopic/video/${videoId}`, true);
};

export const createQuestion = ({
  subTopicId,
  text,
  options,
  explanation,
  type,
}) => {
  return postReq(
    `/content/subtopic/question`,
    { subTopicId, text, options, explanation, type },
    true
  );
};

export const editQuestion = (questionId, { text, options, explanation }) => {
  return putReq(
    `/content/subtopic/question`,
    { questionId, text, options, explanation },
    true
  );
};

export const deleteQuestion = (questionId) => {
  return delReq(`/content/subtopic/question/${questionId}`, true);
};

export const usePreparationPlan = (planId) => {
  const {
    data = {
      preparationPlan: {},
      progress: [],
      chain: [],
    },
    isLoading,
    error,
  } = useSWR(
    planId ? `/preparation-plan/${planId}` : `/preparation-plan`,
    getReq
  );

  const progressMap = data.progress.reduce((acc, subTopicProgress) => {
    acc[subTopicProgress.subTopicId] = subTopicProgress;
    return acc;
  }, {});

  const currentPlanIndex = data.chain?.findIndex(
    (plan) => plan._id === data.preparationPlan._id
  );
  const prev = data.chain?.slice(0, currentPlanIndex);
  const next = data.chain?.slice(currentPlanIndex + 1);

  return {
    data: { ...data, progress: progressMap, prev, next },
    isLoading,
    error,
  };
};

export const getPreparationPlan = (planId) => {
  return getReq(`/preparation-plan/${planId}`, {}, true);
};

export const getPreparationPlanTests = (preparationPlanId) => {
  return getReq(`/preparation-plan/${preparationPlanId}/practice`, {}, true);
};

export const englishPracticeCheck = (data) => {
  return postReq(`/practice/english`, data, true);
};

export const getTranscript = (file, data) => {
  return postFileReq(`/practice/english/transcript`, file, data);
};
