import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import { createVideo } from "../api/content";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const languageOptions = ["tinglish", "english"];

export const VideoForm = ({ open, obj, onSubmit, handleClose }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [languages, setLanguages] = useState([]);
  const [locked, setLocked] = useState(true);

  useEffect(() => {
    if (obj) {
      setTitle(obj.title);
      setDescription(obj.description);
      setLanguages(obj.languages || []);
      setLocked(obj.locked);
    }
  }, [obj]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{obj ? "Edit" : "Add"} Video</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {obj ? "Edit" : "Add"} a video to subtopic
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="title"
          label="Title"
          type="text"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          margin="dense"
          id="description"
          label="Description"
          type="text"
          fullWidth
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <FormControlLabel
          labelPlacement="start"
          label="Languages: "
          control={
            <Select
              multiple
              displayEmpty
              value={languages}
              onChange={(e) => setLanguages(e.target.value)}
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
              sx={{ m: 1, ml: 0, mr: 0 }}
            >
              {languageOptions.map((lang) => (
                <MenuItem key={lang} value={lang}>
                  {lang}
                </MenuItem>
              ))}
            </Select>
          }
        />
        <br />
        <FormControlLabel
          control={
            <Checkbox
              checked={locked}
              onChange={(e) => setLocked(e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Locked by default?"
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onSubmit({ title, description, languages, locked })}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AddSubTopicVideoCard = ({ subTopicId, onAdd, horizontal }) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAdd = (data) => {
    createVideo({ subTopicId, ...data }).then((res) => {
      onAdd?.(res.video);
      handleClose();
    });
  };

  return (
    <>
      <VideoForm open={open} onSubmit={handleAdd} handleClose={handleClose} />
      <Button
        variant="outlined"
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={handleClickOpen}
      >
        <AddIcon sx={{ height: 60, width: 60, color: "primary" }} />
        Add new video
      </Button>
    </>
  );
};

export default AddSubTopicVideoCard;
