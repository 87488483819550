import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { IconButton, InputBase, Paper } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";

export const InputSend = ({
  initialValue = "",
  placeholder = "write here...",
  onSubmit,
  onCancel,
}) => {
  const [value, setValue] = useState(initialValue);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await onSubmit(value);
      setValue("");
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setValue("");
    onCancel();
  };

  return (
    <Paper
      sx={{
        p: "2px 4px",
        display: "flex",
        marginTop: "auto",
        alignItems: "center",
        mt: "auto",
      }}
      variant="outlined"
    >
      <InputBase
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        sx={{ ml: 1, flex: 1 }}
        multiline
        maxRows={4}
        onKeyPress={(e) => {
          console.log(e.key, e.ctrlKey, e.metaKey);
          if (e.key === "Enter" && (e.ctrlKey || e.metaKey)) {
            e.preventDefault();
            handleSubmit();
          }
        }}
        helperText="Press ctrl+enter to send"
      />
      {loading ? (
        <LoadingButton loading>Submit</LoadingButton>
      ) : (
        <>
          <IconButton
            disabled={!value}
            sx={{ p: "10px" }}
            aria-label="cancel"
            onClick={handleCancel}
          >
            <CloseIcon />
          </IconButton>
          <IconButton
            type="submit"
            disabled={!value}
            sx={{ p: "10px" }}
            aria-label="send"
            onClick={handleSubmit}
          >
            <SendIcon color="primary" />
          </IconButton>
        </>
      )}
    </Paper>
  );
};
