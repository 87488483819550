import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  PlaylistPlay as PlaylistPlayIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SwipeableDrawer,
  Typography,
  styled,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useCallback, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router-dom";
import { getVideoDetails, useSubTopicDetails } from "../api/content";
import { updateProgress } from "../api/progress";
import { CodeEditorDrawer } from "../components/CodeEditor";
import Discussions from "../components/Discussions";
import SubTopicVideoCard from "../components/SubTopicVideoCard";
import Popup from "../components/popups";
import Message from "../components/utils/Message";
import subjects from "../data/subjects";
import routes from "../layout/routes";
import {
  ERROR_CODES,
  VIDEO_PROGRESS_CUTOFF,
  lockStatuses,
  plansLink,
} from "../utils/constants";
import { updateProgressInLocal } from "../utils/progress";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

const SubTopicVideos = ({ window }) => {
  const [playerRef, setPlayerRef] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [open, setOpen] = useState(false);
  const [videoLockedAlert, setVideoLockedAlert] = useState(false);
  const [currentVideoDetails, setCurrentVideoDetails] = useState(null);
  const [progressUpdated, setProgressUpdated] = useState(false);
  const [showNoSubscriptionAlert, setShowNoSubscriptionAlert] = useState(false);

  const navigate = useNavigate();
  const discussionsRef = useRef(null);
  const container =
    window !== undefined ? () => window().document.body : undefined;

  let { topicId, subTopicId, videoId } = useParams();
  const {
    data: { subTopic, progress },
    mutate,
  } = useSubTopicDetails(topicId, subTopicId);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const onRefChange = useCallback((node) => {
    setPlayerRef(node);
    if (node === null) {
      // DOM node referenced by ref has been unmounted
    } else {
      // DOM node referenced by ref has changed and exists
    }
  }, []); // adjust deps

  const recordProgress = ({ played, playedSeconds }) => {
    if (playedSeconds !== null) {
      if (!progress[videoId]) {
        progress[videoId] = {};
      }
      progress[videoId] = {
        ...progress[videoId],
        playedSeconds,
        playedFraction: played,
      };
      updateProgressInLocal({
        subTopicId: subTopic._id,
        videoId: videoId,
        playedSeconds,
        playedFraction: played,
      });
      if (played >= VIDEO_PROGRESS_CUTOFF && !progressUpdated) {
        setProgressUpdated(true);
        updateProgress()
          .then(() => mutate())
          .catch((e) => {
            console.log(e);
            setProgressUpdated(false);
          });
      }
    }
  };

  const setInitalSeek = () => {
    const currentPlayerTime = playerRef.getCurrentTime();
    const previousProgress = progress[videoId]?.playedSeconds;
    if (currentPlayerTime !== previousProgress) {
      playerRef.seekTo(previousProgress, "seconds");
    }
  };

  const playVideo = (id) => {
    if (isVideoLocked(id)) {
      setVideoLockedAlert(true);
      return;
    }
    navigate(routes.subTopicVideo.getPath(topicId, subTopicId, id), {
      state: { progress },
    });
  };

  useEffect(() => {
    if (playerRef) {
      setInitalSeek();
    }
  }, [playerRef]);

  useEffect(() => {
    const currentVideo = subTopic?.videos?.find(
      (video) => video._id === videoId
    );
    if (currentVideo) {
      if (isVideoLocked(videoId)) {
        setVideoLockedAlert(true);
      } else {
        setVideoLockedAlert(false);
        setSelectedLanguage(currentVideo.languages[0]);
      }
    }
  }, [subTopic?._id, progress]);

  useEffect(() => {
    if (subTopic?._id && videoId) {
      initVideoDetails();
    }
    return () => {
      updateProgress();
    };
  }, [subTopic?._id, videoId]);

  useEffect(() => {
    setProgressUpdated(false);
  }, [videoId]);

  const askDoubtClick = () => {
    discussionsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const initVideoDetails = async () => {
    try {
      const res = await getVideoDetails({ subTopicId: subTopic._id, videoId });
      setCurrentVideoDetails(res.data.video);
    } catch (e) {
      console.log({ e: e });
      if (e.response?.data?.ERROR_CODE) {
        setShowNoSubscriptionAlert(e.response?.data?.ERROR_CODE);
      }
    }
  };

  const isVideoLocked = useCallback(
    (videoId) => {
      return (
        !progress[videoId]?.status ||
        progress[videoId]?.status === lockStatuses.LOCKED
      );
    },
    [progress]
  );

  const currentVideo = subTopic?.videos?.find((video) => video._id === videoId);

  const programmingLanguage = subjects[topicId]?.language;

  return !currentVideo ? null : (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "row",
        flex: 1,
        height: "100%",
        flexWrap: "wrap",
        p: 1,
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
          alignItems: "center",
          width: "auto",
        },
      })}
    >
      <Popup
        open={videoLockedAlert}
        onClose={() => setVideoLockedAlert(false)}
        title={"Video locked"}
        content={
          "This video is locked. Please complete the previous videos to unlock this video."
        }
        actions={[
          <Button onClick={() => setVideoLockedAlert(false)}>OK</Button>,
        ]}
      />
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          flex: 3,
          pl: 2,
          pr: 2,
          mr: 1,
          height: "100%",
          [theme.breakpoints.down("md")]: {
            p: 1,
            m: 1,
            width: "90%",
          },
          [theme.breakpoints.down("md")]: {
            mb: 8,
          },
        })}
      >
        <Paper variant="outlined">
          <Paper
            variant="outlined"
            sx={(theme) => ({
              width: "auto",
              backgroundColor: "#22222244",
              height: "auto",
            })}
          >
            {showNoSubscriptionAlert ? (
              <Box sx={{ height: 480 }}>
                {showNoSubscriptionAlert === ERROR_CODES.FEATURE_NOT_ENABLED ? (
                  // FEATURE_NOT_ENABLED error is for users who have subscription but don't have access to videos
                  // Hence we show message to contact support but not redirect to plans page
                  // Support can guide them to either upgrade the subscription or enable the feature
                  <Message
                    message="You don't have access to view videos"
                    extra={
                      <Typography>
                        Please{" "}
                        <a
                          href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
                        >
                          contact support
                        </a>{" "}
                        for more information.
                      </Typography>
                    }
                  />
                ) : (
                  // NO_SUBSCRIPTION error is for users who don't have subscription itself. Hence we redirect here to plans page
                  <Message
                    message="You need subscription to view this video"
                    redirectTo={{
                      title: "Plans",
                      path: plansLink,
                      external: true,
                    }}
                    redirectTime={5}
                  />
                )}
              </Box>
            ) : (
              <ReactPlayer
                ref={onRefChange}
                playing
                onProgress={recordProgress}
                style={{ width: "100%", height: "100%" }}
                width="100%"
                height="100%"
                url={
                  currentVideoDetails?.videoUrls.find(
                    (i) => i.language === selectedLanguage
                  )?.url
                }
                controls
                config={{
                  file: { attributes: { controlsList: "nodownload" } },
                }}
              />
            )}
          </Paper>
          <Box
            sx={(theme) => ({
              p: 1,
              display: "flex",
              flexDirection: "row",
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
              },
            })}
          >
            <Box sx={{ marginRight: "auto", p: 1 }}>
              <Typography variant="h5">{currentVideo.title}</Typography>
              <Typography variant="body2">
                {currentVideo.description}
              </Typography>
            </Box>
            <Box
              sx={(theme) => ({
                display: "flex",
                flexDirection: "row",
                [theme.breakpoints.up("sm")]: {
                  flexDirection: "column",
                },
              })}
            >
              <Divider
                sx={(theme) => ({
                  [theme.breakpoints.down("md")]: {
                    display: "none",
                  },
                })}
                orientation="vertical"
                flexItem
              />
              <Button
                variant="contained"
                sx={{ m: 1, flex: 1 }}
                onClick={askDoubtClick}
              >
                Ask a doubt
              </Button>
              <Divider orientation="vertical" flexItem />
              <Box sx={{ flex: 1 }}>
                {/*
                we will not render until some default language is set in state.
                this is to avoid label coming on top of select since when it renders there is no selected language
              */}
                {selectedLanguage && (
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="select-language">Video language</InputLabel>
                    <Select
                      variant="standard"
                      labelId="select-language"
                      value={selectedLanguage}
                      onChange={(e) => setSelectedLanguage(e.target.value)}
                      MenuProps={MenuProps}
                      inputProps={{ "aria-label": "select language" }}
                      sx={{ m: 1, ml: 0, mr: 0, textTransform: "capitalize" }}
                    >
                      {currentVideo.languages?.map((lang) => (
                        <MenuItem
                          key={lang}
                          value={lang}
                          sx={{ textTransform: "capitalize" }}
                        >
                          {lang}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Box>
            </Box>
          </Box>
        </Paper>

        <Box sx={{ mt: 2, mb: 1 }} ref={discussionsRef}>
          <Discussions videoId={videoId} />
        </Box>
      </Box>
      <Divider
        orientation="horizontal"
        flexItem
        sx={(theme) => ({
          [theme.breakpoints.up("md")]: {
            display: "none",
          },
        })}
      />

      {/* subtopic videos */}

      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          width: "24%",
          height: "100%",
          flex: 1,
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        })}
      >
        <Typography variant="h5" gutterBottom>
          Playlist
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          flexWrap="wrap"
          sx={{ width: "100%", overflow: "auto", mr: 1, mb: 1, pr: 2 }}
          spacing={1}
        >
          {subTopic?.videos?.map((v, index) => (
            <Grid item xs={12} key={v._id}>
              <SubTopicVideoCard
                key={v._id}
                isPlaying={videoId === v._id}
                obj={v}
                playVideo={() => playVideo(v._id)}
                horizontal
                status={progress[v._id]?.status}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* code editor */}
      {programmingLanguage && (
        <>
          <Divider
            orientation="vertical"
            flexItem
            sx={(theme) => ({
              [theme.breakpoints.down("md")]: {
                display: "none",
              },
            })}
          />
          <CodeEditorDrawer language={programmingLanguage} />
        </>
      )}

      {/* Floating action button for playlist */}
      <Box
        sx={(theme) => ({
          [theme.breakpoints.up("md")]: {
            display: "none",
          },
        })}
      >
        <Fab
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
            p: 2,
          }}
          variant="extended"
          color="primary"
          aria-label="add"
          onClick={toggleDrawer(true)}
        >
          <PlaylistPlayIcon sx={{ mr: 1 }} />
          Playlist
        </Fab>
        <SwipeableDrawer
          container={container}
          anchor="bottom"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          disableSwipeToOpen={false}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Box
            sx={{
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
            }}
          >
            <Puller />
            <Box
              onClick={toggleDrawer(false)}
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography variant="h5" sx={{ flex: 1 }}>
                Playlist
              </Typography>
              <IconButton sx={{ marginLeft: "auto" }}>
                <KeyboardArrowDownIcon />
              </IconButton>
            </Box>
          </Box>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            flexWrap="wrap"
            sx={(theme) => ({
              width: "100%",
              overflow: "auto",
              mb: 1,
              p: 2,
              pt: 1,
            })}
            spacing={1}
          >
            {subTopic?.videos?.map((v, index) => (
              <Grid item xs={12} key={v._id}>
                <SubTopicVideoCard
                  key={v._id}
                  isPlaying={videoId === v._id}
                  obj={v}
                  playVideo={() => playVideo(v._id)}
                  horizontal
                  status={progress[v._id]?.status}
                />
              </Grid>
            ))}
          </Grid>
        </SwipeableDrawer>
      </Box>
    </Box>
  );
};

export default SubTopicVideos;
