import { useRef, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Divider,
  createTheme,
  ThemeProvider,
  Box,
  IconButton,
  MenuItem,
  Menu,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineConnector,
  TimelineSeparator,
  TimelineDot,
  TimelineContent,
  TimelineOppositeContent,
} from "@mui/lab";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import dayjs from "dayjs";
import { testLink, testStatuses } from "../utils/constants";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const theme = createTheme({
  palette: {
    dark: {
      main: "#333",
      contrastText: "#fff",
    },
  },
});

export const TestSectionsTimeline = ({
  obj,
  compressed = false,
  onViewAll,
}) => {
  const sections = compressed ? obj.sections.slice(0, 2) : obj.sections;
  return (
    <Timeline sx={{ pl: 0, pt: 0, mt: 0, height: 120 }}>
      {sections.map((section, index) => (
        <TimelineItem
          sx={{
            ":before": {
              paddingLeft: 0,
              marginLeft: 0,
              flex: 0,
            },
          }}
          key={index}
        >
          <TimelineSeparator>
            <TimelineDot />
            {index === obj.sections.length - 1 ? null : <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="body1" gutterBottom component="div">
              {section.title}
            </Typography>
            <Typography variant="body2" gutterBottom component="div">
              Duration: {section.duration} minutes
            </Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
      {obj.sections.length > sections.length && (
        <ThemeProvider theme={theme}>
          <Button
            variant="outlined"
            size="small"
            color="dark"
            onClick={onViewAll}
          >
            View All
          </Button>
        </ThemeProvider>
      )}
    </Timeline>
  );
};

export const TestCardActions = ({ obj }) => {
  return obj.status === testStatuses.FINISHED ||
    obj.status === testStatuses.SUBMITTED ? (
    <a
      href={process.env.REACT_APP_TEST_WEB + "/test/report/" + obj._id}
      target="_blank"
      rel="noreferrer"
      style={{ textDecoration: "none" }}
    >
      <Button size="small" endIcon={<OpenInNewIcon fontSize="16" />}>
        Show Report
      </Button>
    </a>
  ) : obj.status === testStatuses.IN_PROGRESS ||
    obj.status === testStatuses.NOT_STARTED ? (
    <a
      href={testLink(obj._id)}
      target="_blank"
      rel="noreferrer"
      style={{ textDecoration: "none" }}
    >
      <Button size="small">Take Test</Button>
    </a>
  ) : (
    <Typography variant="button" sx={{ pl: 1, pr: 1 }}>
      Starts at {dayjs(obj.startTime).format("MMM DD, YYYY hh:mm A")}
    </Typography>
  );
};

const TestCard = ({ obj, onDelete, onExpand }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef(null);

  return (
    <>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "fit-content",
          height: "auto",
          overflow: "visible",
          m: 1,
        }}
        variant="outlined"
      >
        <CardContent sx={{ height: 260, pt: 1 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 0,
            }}
          >
            <Typography variant="h5" component="div">
              {obj.title}
            </Typography>
            <IconButton
              ref={anchorRef}
              aria-label="more"
              id="long-button"
              aria-controls={menuOpen ? "long-menu" : undefined}
              aria-expanded={menuOpen ? "true" : undefined}
              aria-haspopup="true"
              onClick={() => setMenuOpen(true)}
              sx={{ mr: -1 }}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
          <Divider />
          <Typography
            sx={{ mt: 1 }}
            variant="subtitle1"
            gutterBottom
            component="div"
          >
            Sections:
          </Typography>
          <TestSectionsTimeline obj={obj} compressed onViewAll={onExpand} />
        </CardContent>
        <CardActions sx={{ marginTop: "auto", height: "auto" }}>
          <TestCardActions obj={obj} />
        </CardActions>
      </Card>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorRef?.current}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        <MenuItem onClick={onDelete}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default TestCard;
