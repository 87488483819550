import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import withAuth from "../hoc/withAuth";
import {
  PlayArrowRounded as PlayArrowRoundedIcon,
  Equalizer as EqualizerIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  Edit as EditIcon,
  DeleteOutline as DeleteIcon,
  Lock as LockIcon,
} from "@mui/icons-material";
import { LockUnlockIcon } from "./utils/icons";
import { lockStatuses } from "../utils/constants";

const SubTopicVideoCard = ({
  obj,
  isPlaying,
  playVideo,
  isSuperAdmin,
  moveLeft,
  moveRight,
  editVideo,
  deleteVideo,
  playedFraction,
  status,
}) => {
  return (
    <Card
      variant="outlined"
      sx={(theme) => ({
        width: "auto",
        height: "auto",
        [theme.breakpoints.down("md")]: {
          width: "100%",
        },
      })}
    >
      <CardMedia
        sx={{
          height: 180,
          width: "100%",
          "&:hover": {
            cursor: "pointer",
          },
          backgroundColor: "#fff",
        }}
      >
        <div
          style={{
            display: "flex",
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          <img
            src={obj.thumbnailUrl}
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
            }}
            alt="Live from space album cover"
          />
          <Box
            sx={{
              position: "absolute",
              left: "0",
              top: "0",
              width: "100%",
              height: "100%",
              opacity: isPlaying ? "100%" : 0,
              background: "#aaaaaa44",
              "&:hover": {
                opacity: "100%",
              },
            }}
          >
            {isPlaying ? (
              <IconButton
                aria-label="now playing"
                sx={{ width: "100%", height: "100%", borderRadius: 0 }}
              >
                <EqualizerIcon
                  sx={{ height: 80, width: 80, color: "#F0F3F4" }}
                />
              </IconButton>
            ) : status === lockStatuses.UNLOCKED ||
              status === lockStatuses.FINISHED ? (
              <IconButton
                aria-label="play/pause"
                sx={{ width: "100%", height: "100%", borderRadius: 0 }}
                onClick={playVideo}
              >
                <PlayArrowRoundedIcon
                  sx={{ height: 80, width: 80, color: "#F0F3F4" }}
                />
              </IconButton>
            ) : (
              <IconButton
                aria-label="locked"
                sx={{ width: "100%", height: "100%", borderRadius: 0 }}
                onClick={playVideo}
              >
                <LockIcon sx={{ height: 80, width: 80, color: "#F0F3F4" }} />
              </IconButton>
            )}
          </Box>
        </div>
      </CardMedia>
      <LinearProgress
        variant="determinate"
        value={playedFraction ? playedFraction * 100 : 0}
        color="error"
      />
      <CardContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <Box sx={{ minWidth: "70%" }}>
            <Typography
              variant="h6"
              component="div"
              sx={{ fontWeight: "400" }}
              title={obj.title}
              noWrap
            >
              {obj.title}
            </Typography>
            <Typography variant="body2" noWrap title={obj.description}>
              {obj.description}
            </Typography>
          </Box>

          <Divider sx={{ ml: 2, mr: 2 }} orientation="vertical" flexItem />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "10%",
            }}
          >
            <LockUnlockIcon status={status} />
          </Box>
        </Box>
      </CardContent>
      {isSuperAdmin && (
        <CardActions>
          <IconButton aria-label="move-left" onClick={moveLeft}>
            <ArrowBackIcon />
          </IconButton>
          <IconButton aria-label="move-right" onClick={moveRight}>
            <ArrowForwardIcon />
          </IconButton>
          <IconButton aria-label="edit" onClick={editVideo}>
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="move-right"
            color="error"
            onClick={deleteVideo}
          >
            <DeleteIcon />
          </IconButton>
        </CardActions>
      )}
    </Card>
  );
};

export default withAuth(SubTopicVideoCard);
