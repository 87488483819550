import { createContext, useEffect, useReducer, useState } from "react";
import "./App.css";
import { getUserDetails } from "./api/user";
import EnglishPracticePopup from "./components/popups/EnglishPracticePopup";
import Layout from "./layout";
import { homeViews } from "./utils/constants";

export const StoreContext = createContext();

const initialState = {
  isAuthenticated: false,
  user: null,
  homeView: homeViews.TOPICS,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        homeView: homeViews.PREPARATION_PLAN,
      };
    case "LOGOUT":
      return {
        isAuthenticated: false,
        user: null,
      };
    case "UPDATE_HOME_VIEW":
      return {
        ...state,
        homeView: action.payload.homeView,
      };
    default:
      return state;
  }
};

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [englishPracticeVisible, setEnglisPracticeVisible] = useState(false);

  useEffect(() => {
    getUserDetails().then((res) => {
      dispatch({
        type: "LOGIN",
        payload: {
          user: res.user,
        },
      });
    });
  }, []);

  return (
    <StoreContext.Provider
      value={{
        state,
        dispatch,
        showEnglishPractice: () => setEnglisPracticeVisible(true),
      }}
    >
      <Layout />
      <EnglishPracticePopup
        open={englishPracticeVisible}
        handleClose={() => setEnglisPracticeVisible(false)}
      />
    </StoreContext.Provider>
  );
}

export default App;
