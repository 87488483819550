import { Box } from "@mui/material";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createTestProfile } from "../api/test";
import TestForm from "../components/TestForm";
import routes from "../layout/routes";

const CreateTestProfile = () => {
  const navigate = useNavigate();
  const errorRef = useRef(null);

  const [errors, setErrorMessages] = useState([]);

  const setErrors = (errors) => {
    setErrorMessages(errors);
    if (errors.length !== 0) {
      errorRef.current.scrollIntoView();
    }
  };

  const onCreate = async ({
    title,
    testType,
    sections,
    allowSectionSwitching,
  }) => {
    try {
      await createTestProfile({
        title,
        testType,
        sections,
        allowSectionSwitching,
      });
      setErrors([]);
      navigate(routes.testProfiles.path);
    } catch (err) {
      console.error("error", err.response);
      if (err.response?.data?.message) {
        setErrors(err.response.data.message.split("\n"));
      } else {
        setErrors(["Unknown error"]);
      }
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          overflow: "auto",
          width: "100%",
          height: "100%",
        }}
        ref={errorRef}
      >
        <TestForm isTestProfile errors={errors} onSubmit={onCreate} />
      </div>
    </Box>
  );
};

export default CreateTestProfile;
