import axios from "axios";
import { Buffer } from "buffer";

const BACKEND_URL =
  process.env.REACT_APP_CODE_BACKEND_URL ||
  "https://backend.code.easyhiring.in";

export const handleCompile = async ({ code, languageId, input }) => {
  const formData = {
    language_id: languageId,
    // encode source code in base64
    source_code: btoa(code),
    stdin: btoa(input),
  };
  const options = {
    method: "POST",
    url: BACKEND_URL + "/submissions",
    params: { base64_encoded: "true", fields: "*" },
    withCredentials: false,
    headers: {
      "content-type": "application/json",
      "Content-Type": "application/json",
    },
    data: formData,
  };

  const response = await axios.request(options);
  const token = response.data.token;
  return token;
};

export const checkStatus = async ({
  token,
  setProcessing,
  setError,
  setOutput,
}) => {
  const options = {
    method: "GET",
    url: BACKEND_URL + "/submissions/" + token,
    params: { base64_encoded: "true", fields: "*" },
    withCredentials: false,
  };
  try {
    let response = await axios.request(options);
    let statusId = response.data.status?.id;

    // Processed - we have a result
    if (statusId === 1 || statusId === 2) {
      // still processing
      setTimeout(() => {
        checkStatus({ token, setProcessing, setError, setOutput });
      }, 300);
      return;
    } else {
      setProcessing(false);

      if (statusId === 6) {
        // compilation error
        setError(
          Buffer.from(response.data.compile_output, "base64").toString("utf-8")
        );
      } else if (statusId === 3) {
        // success
        console.log(
          Buffer.from(response.data.stdout, "base64").toString("utf-8")
        );
        setOutput(
          Buffer.from(response.data.stdout, "base64").toString("utf-8")
        );
      } else if (statusId === 5) {
        // time limit exceeded
        setError("Time Limit Exceeded");
      } else {
        // runtime error
        setError(
          Buffer.from(
            response.data.stderr ||
              response.data.compile_output ||
              response.data.message,
            "base64"
          ).toString("utf-8")
        );
      }
      return;
    }
  } catch (err) {
    console.log("err", err);
    setProcessing(false);
  }
};
