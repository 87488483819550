import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { TakeMockButton } from "./utils/buttons";

export default function SubTopicBigCard({
  id,
  topicId,
  title,
  description,
  color,
  primaryColor,
}) {
  return (
    <Card
      variant="outlined"
      sx={{
        margin: 1,
        width: "100%",
        mihHeight: 260,
        maxHeight: 280,
        display: "flex",
        flexDirection: "column",
        borderRadius: 2,
        backgroundColor: color,
      }}
    >
      <CardContent
        sx={{
          backgroundColor: color,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ fontWeight: "bold" }}
          >
            {title}
          </Typography>
          <TakeMockButton
            topicId={topicId}
            subTopicId={id}
            primaryColor={primaryColor}
          />
        </Box>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
}
