export const convertToMap = (list = [], key) => {
  return list.reduce((acc, item) => {
    acc[item[key]] = item;
    return acc;
  }, {});
};

export const sortByDescending = (listOfObjects, key) => {
  return listOfObjects.sort((a, b) => (a[key] > b[key] ? -1 : 1));
};

export const sortByAscending = (listOfObjects, key) => {
  return listOfObjects?.sort((a, b) => (a[key] > b[key] ? 1 : -1));
};
