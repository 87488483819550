import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  AccordionActions,
  Button,
} from "@mui/material";
import withAuth from "../hoc/withAuth";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const ArticleCard = ({ obj, isSuperAdmin, handleEdit, handleDelete }) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography>{obj.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{ height: "auto", width: "80%" }}
          dangerouslySetInnerHTML={{ __html: obj.content }}
        />
      </AccordionDetails>
      {isSuperAdmin && (
        <AccordionActions>
          <Button startIcon={<EditIcon />} onClick={handleEdit}>
            Edit
          </Button>
          <Button
            startIcon={<DeleteIcon />}
            color="error"
            onClick={handleDelete}
            sx={{ marginLeft: 2 }}
          >
            Delete
          </Button>
        </AccordionActions>
      )}
    </Accordion>
  );
};

export default withAuth(ArticleCard);
