import {
  amber,
  blue,
  blueGrey,
  grey,
  indigo,
  lightBlue,
  orange,
  teal,
  yellow,
} from "@mui/material/colors";
import assets from "../utils/assets";

export const topicGroups = {
  analytics: "Analytical Ability + Verbal Reasoning",
  programming: "Programming",
  technology: "Technology",
};

const subjects = {
  English: {
    thumbnail: assets.english,
    primaryColor: indigo[800],
    secondaryColor: indigo[500],
    color: indigo[50],
    group: topicGroups.analytics,
  },
  Aptitude: {
    title: "Aptitude",
    thumbnail: assets.aptitude,
    primaryColor: yellow[800],
    secondaryColor: yellow[500],
    color: yellow[50],
    description:
      "Aptitude is the natural ability of humans which comes to rescue while solving any kind of problems.",
    group: topicGroups.analytics,
  },
  "Verbal Ability": {
    title: "Verbal Ability",
    thumbnail: assets.verbalAbility,
    primaryColor: blue[700],
    secondaryColor: blueGrey[500],
    color: blue[50],
    description:
      "Verbal Ability is defined as the capability of a person in expressing ideas using words in a clearly understandable manner.",
    group: topicGroups.analytics,
  },
  "Logical Reasoning": {
    title: "Logical Reasoning",
    thumbnail: assets.logicalReasoning,
    primaryColor: teal[700],
    secondaryColor: blueGrey[500],
    color: teal[50],
    description:
      "Logical reasoning is a form of thinking in which premises are used in a rigorous manner to infer conclusions from them.",
    group: topicGroups.analytics,
  },
  Programming: {
    title: "Programming",
    thumbnail: assets.programming,
    primaryColor: grey[900],
    secondaryColor: blueGrey[500],
    color: grey[200],
    description:
      "Programming is the process of performing a particular computation usually by designing/building an executable computer program",
    group: topicGroups.programming,
  },
  "C Programming": {
    thumbnail: assets.cProgramming,
    primaryColor: blue[700],
    secondaryColor: blue[500],
    color: blue[50],
    language: "c",
    group: topicGroups.programming,
  },
  "C++ Programming": {
    thumbnail: assets.cppProgramming,
    primaryColor: indigo[700],
    secondaryColor: indigo[500],
    color: indigo[50],
    language: "cpp",
    group: topicGroups.programming,
  },
  Python: {
    thumbnail: assets.pythonProgramming,
    primaryColor: amber[800],
    secondaryColor: amber[500],
    color: amber[50],
    language: "python",
    group: topicGroups.programming,
  },
  Java: {
    thumbnail: assets.javaProgramming,
    primaryColor: lightBlue[700],
    secondaryColor: lightBlue[500],
    color: lightBlue[50],
    language: "java",
    group: topicGroups.programming,
  },
  "Machine Learning": {
    thumbnail: assets.machineLearning,
    primaryColor: grey[900],
    secondaryColor: blueGrey[500],
    color: grey[200],
    group: topicGroups.technology,
  },
  "Artificial Intelligence": {
    thumbnail: assets.artificialIntelligence,
    primaryColor: blue[900],
    secondaryColor: blue[500],
    color: blue[50],
    group: topicGroups.technology,
  },
  "Frontend Development": {
    thumbnail: assets.frontendDevelopment,
    primaryColor: orange[700],
    secondaryColor: orange[500],
    color: orange[50],
    group: topicGroups.technology,
  },
  "Backend Development": {
    thumbnail: assets.backendDevelopment,
    primaryColor: blue[700],
    secondaryColor: blue[500],
    color: blue[50],
    group: topicGroups.technology,
  },
};

export default subjects;
