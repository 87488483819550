import { Routes, Route } from "react-router-dom";
import routes from "./routes";

const Router = () => {
  return (
    <Routes>
      {Object.keys(routes).map((key) => (
        <Route
          key={key}
          path={routes[key].path}
          element={routes[key].component}
        />
      ))}
    </Routes>
  );
};

export default Router;
