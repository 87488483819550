import EastIcon from "@mui/icons-material/East";
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { usePreparationPlan } from "../api/content";
import PreparationPlan from "../components/PreparationPlan";
import Popup from "../components/popups";
import Loading from "../components/utils/Loading";
import styles from "./styles";

const Home = () => {
  const [selectedPlanId, setSelectedPlanId] = useState(null);

  const {
    data: { preparationPlan, progress, prev, next },
    isLoading,
  } = usePreparationPlan();

  const {
    data: { preparationPlan: selectedPlan, progress: selectedPlanProgress },
    isLoading: planDetailsLoading,
  } = usePreparationPlan(selectedPlanId);

  const showPreparationPlan = async (plan) => {
    setSelectedPlanId(plan._id);
  };

  return (
    <Box
      sx={(theme) => ({
        height: "100%",
        width: "auto",
        overflowY: "auto",
        paddingLeft: 5,
        paddingRight: 5,
        mt: 2,
        pb: 1,
        [theme.breakpoints.down("md")]: {
          p: 0,
          m: 0,
        },
      })}
    >
      <Popup
        open={selectedPlanId}
        title={selectedPlan?.title || "Preparation Plan"}
        content={
          planDetailsLoading ? (
            <Loading />
          ) : selectedPlan ? (
            <PreparationPlan
              preparationPlan={selectedPlan}
              progress={selectedPlanProgress}
            />
          ) : null
        }
        onClose={() => setSelectedPlanId(null)}
        width="md"
      />
      {isLoading ? (
        <XSkeleton />
      ) : (
        <>
          {preparationPlan?._id ? (
            <PreparationPlan
              preparationPlan={preparationPlan}
              progress={progress}
            />
          ) : (
            <Typography variant="h6">No Preparation Plan</Typography>
          )}
        </>
      )}

      <Box sx={styles.well}>
        <Typography variant="h6" gutterBottom>
          Upcoming learnings
        </Typography>
        {next?.length > 0 ? (
          <List sx={{ bgcolor: "background.paper" }}>
            {next.map((plan) => (
              <ListItemButton
                key={plan._id}
                onClick={() => showPreparationPlan(plan)}
              >
                <ListItemText primary={plan.title} />
                <ListItemIcon>
                  <EastIcon />
                </ListItemIcon>
              </ListItemButton>
            ))}
          </List>
        ) : (
          <Typography variant="body">No upcoming learnings</Typography>
        )}
      </Box>

      {prev?.length > 0 && (
        <Box sx={styles.well}>
          <Typography variant="h6">Previous learnings</Typography>
          <List sx={{ bgcolor: "background.paper" }}>
            {prev.map((plan) => (
              <ListItemButton
                key={plan._id}
                onClick={() => showPreparationPlan(plan)}
              >
                <ListItemText primary={plan.title} />
                <ListItemIcon>
                  <EastIcon />
                </ListItemIcon>
              </ListItemButton>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

const XSkeleton = () => {
  return (
    <Stack spacing={2}>
      <ListSubheader>
        <Skeleton width={80} height={36} />
      </ListSubheader>
      <Skeleton variant="rounded" width="100%" height={68} animation="wave" />
      <Skeleton variant="rounded" width="100%" height={68} animation={false} />

      <ListSubheader>
        <Skeleton width={80} height={36} />
      </ListSubheader>
      <Skeleton variant="rounded" width="100%" height={68} />
      <Skeleton variant="rounded" width="100%" height={68} animation="wave" />

      <ListSubheader>
        <Skeleton width={80} height={36} />
      </ListSubheader>
      <Skeleton variant="rounded" width="100%" height={68} />
      <Skeleton variant="rounded" width="100%" height={68} animation="wave" />
    </Stack>
  );
};

export default Home;
