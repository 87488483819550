import assets from "../utils/assets";
import { grey, blueGrey } from "@mui/material/colors";

const companies = {
  "tcs nqt": {
    thumbnail: assets.tcs,
    primaryColor: grey[800],
    secondaryColor: blueGrey[500],
    color: grey[50],
  },
  "tcs digital": {
    thumbnail: assets.tcs,
    primaryColor: grey[800],
    secondaryColor: blueGrey[500],
    color: grey[50],
  },
  "tcs bps": {
    thumbnail: assets.tcs,
    primaryColor: grey[800],
    secondaryColor: blueGrey[500],
    color: grey[50],
  },
  infosys: {
    thumbnail: assets.infosys,
    primaryColor: grey[800],
    secondaryColor: blueGrey[500],
    color: grey[50],
  },
  wipro: {
    thumbnail: assets.wipro,
    primaryColor: grey[800],
    secondaryColor: blueGrey[500],
    color: grey[50],
  },
  cognizant: {
    thumbnail: assets.cognizant,
    primaryColor: grey[800],
    secondaryColor: blueGrey[500],
    color: grey[50],
  },
  accenture: {
    thumbnail: assets.accenture,
    primaryColor: grey[800],
    secondaryColor: blueGrey[500],
    color: grey[50],
  },
  "tech mahindra": {
    thumbnail: assets.techMahindra,
    primaryColor: grey[800],
    secondaryColor: blueGrey[500],
    color: grey[50],
  },
  dxc: {
    thumbnail: assets.dxc,
    primaryColor: grey[800],
    secondaryColor: blueGrey[500],
    color: grey[50],
  },
};

export default companies;
