import ErrorIcon from "@mui/icons-material/Error";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import MicIcon from "@mui/icons-material/Mic";
import ReplayIcon from "@mui/icons-material/Replay";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Popup from ".";
import { englishPracticeCheck, getTranscript } from "../../api/content";

const RECORDING_STATUS = {
  NOT_STARTED: "NOT_STARTED",
  RECORDING: "RECORDING",
  PROCESSING: "PROCESSING",
  FINISHED: "FINISHED",
  FAILED: "FAILED",
};

const MicButton = ({ onFinish }) => {
  const [recordingStatus, setRecordingStatus] = useState(
    RECORDING_STATUS.NOT_STARTED
  );
  const [recorder, setRecorder] = useState(null);
  let chunks = [];

  useEffect(() => {
    if (recorder) {
      recorder.onstart = () => {
        setRecordingStatus(RECORDING_STATUS.RECORDING);
      };

      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: "audio/wav" });
        const file = new File([blob], "audio.wav", {
          type: "audio/wav",
        });
        setRecordingStatus(RECORDING_STATUS.PROCESSING);
        onFinish(file)
          .then(() => setRecordingStatus(RECORDING_STATUS.FINISHED))
          .catch((e) => {
            setRecordingStatus(RECORDING_STATUS.FAILED);
            alert("Failed to process audio. Please try again.");
            console.log(e);
          });
        chunks = [];
      };

      recorder.ondataavailable = (e) => {
        chunks.push(e.data);
      };

      recorder.start();
    }
  }, [recorder]);

  const init = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia(
          // constraints - only audio needed for this app
          {
            audio: true,
          }
        )

        // Success callback
        .then((stream) => {
          const mediaRecorder = new MediaRecorder(stream);
          setRecorder(mediaRecorder);
        })

        // Error callback
        .catch((err) => {
          alert("Failed to get audio permissions. Please try again.");
          console.error(`The following getUserMedia error occurred: ${err}`);
        });
    } else {
      alert("Audio recording not supported on your browser!");
      console.log("getUserMedia not supported on your browser!");
    }
  };

  const startRecording = () => {
    // Start recording. Browser will request permission to use your microphone.
    if (!recorder) {
      init();
    } else {
      recorder.start();
    }
  };

  const stopRecording = () => {
    recorder.stop();
    recorder.getTracks().forEach((track) => track.stop());
    recorder.stream.getTracks().forEach((track) => track.stop());
  };

  const stopProcessing = () => {
    setRecordingStatus(RECORDING_STATUS.NOT_STARTED);
  };

  return (
    <IconButton>
      {recordingStatus === RECORDING_STATUS.NOT_STARTED ? (
        <MicIcon onClick={startRecording} />
      ) : recordingStatus === RECORDING_STATUS.RECORDING ? (
        <Box
          sx={{
            border: "1px solid red",
            borderRadius: 25,
            display: "flex",
          }}
        >
          <FiberManualRecordIcon
            color="error"
            className="blink-animation"
            onClick={stopRecording}
          />
        </Box>
      ) : recordingStatus === RECORDING_STATUS.PROCESSING ? (
        <CircularProgress size={20} onClick={stopProcessing} />
      ) : recordingStatus === RECORDING_STATUS.FINISHED ? (
        <ReplayIcon onClick={startRecording} />
      ) : recordingStatus === RECORDING_STATUS.FAILED ? (
        <ErrorIcon onClick={startRecording} />
      ) : null}
    </IconButton>
  );
};

const EnglishPracticePopup = ({ open, handleClose }) => {
  const [teluguText, setTeluguText] = useState("");
  const [englishText, setEnglishText] = useState("");
  const [showClear, setShowClear] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const check = async () => {
    if (!teluguText || !englishText) return;
    setLoading(true);
    setMessage("");
    try {
      const response = await englishPracticeCheck({ teluguText, englishText });
      setMessage(response.message);
      setShowClear(true);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const clearAll = () => {
    setTeluguText("");
    setEnglishText("");
    setMessage("");
    setShowClear(false);
  };

  const onNativeFinish = async (audioFile) => {
    const res = await getTranscript(audioFile, { language: "te" });
    setTeluguText(res.transcript);
  };
  const onEnglishFinish = async (audioFile) => {
    const res = await getTranscript(audioFile, { language: "en-IN" });
    setEnglishText(res.transcript);
  };

  return (
    <Popup
      open={open}
      onClose={handleClose}
      title={`Practice English`}
      content={
        <>
          <Typography
            variant="body1"
            fontWeight="normal"
            gutterBottom
            component="div"
          >
            Test your English skills. Write a sentence in Telugu and then try to
            write the same sentence in English.
          </Typography>
          <TextField
            margin="dense"
            id="title"
            label="Telugu Text"
            type="text"
            fullWidth
            placeholder="mee peru enti?"
            value={teluguText}
            onChange={(e) => setTeluguText(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <MicButton onFinish={onNativeFinish} />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="dense"
            id="title"
            label="English Text"
            type="text"
            fullWidth
            placeholder="What is your name?"
            value={englishText}
            onChange={(e) => setEnglishText(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <MicButton onFinish={onEnglishFinish} />
                </InputAdornment>
              ),
            }}
          />
          {message && (
            <Alert severity="info" sx={{ m: 1, width: "auto", mt: 2, mb: 4 }}>
              {message}
            </Alert>
          )}
          {showClear && (
            <Button
              sx={{ width: "100%" }}
              size="large"
              variant="outlined"
              onClick={clearAll}
              disabled={loading}
            >
              Clear All
            </Button>
          )}
          <LoadingButton
            sx={{ width: "100%" }}
            size="large"
            variant="contained"
            onClick={check}
            loading={loading}
          >
            Check
          </LoadingButton>
        </>
      }
    />
  );
};

export default EnglishPracticePopup;
