import { getReq, postReq, delReq, putReq } from ".";

export const getCompanies = () => {
  return getReq(`/company`, {}, true);
};

export const getCompanyDetails = (companyId) => {
  return getReq(`/company`, { companyId }, true);
};

export const updateCompany = ({ companyId, content, topics }) => {
  return putReq(`/company/content`, { companyId, content, topics }, true);
};
