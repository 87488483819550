import { useState } from "react";
import {
  Box,
  Button,
  Drawer,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import Editor from "react-simple-code-editor";
import Editor from "@monaco-editor/react";
import { checkStatus, handleCompile, runCode } from "../api/code";
import DarkModeSwitch from "./DarkModeSwitch";

const availableLanguages = {
  c: { title: "C", languageId: 50 },
  cpp: { title: "C++", languageId: 54 },
  go: { title: "Go", languageId: 60 },
  java: { title: "Java", languageId: 62 },
  javascript: { title: "Javascript", languageId: 63 },
  python: { title: "Python", languageId: 71 },
};

const CodeEditor = ({ language = "python" }) => {
  const [processing, setProcessing] = useState(false);
  const [theme, setTheme] = useState("vs-dark");
  const [code, setCode] = useState("");
  const [output, setOutput] = useState(null);
  const [error, setError] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(language);

  const handleDarkModeChange = (e) => {
    setTheme(e.target.checked ? "vs-dark" : "vs");
  };

  const run = async () => {
    setProcessing(true);
    setOutput(null);
    setError(null);
    try {
      const token = await handleCompile({
        code,
        languageId: availableLanguages[selectedLanguage].languageId,
      });
      checkStatus({
        token,
        setProcessing,
        setError,
        setOutput,
      });
    } catch (err) {
      console.log("err", err);
      setProcessing(false);
      setError(err);
    }
  };

  // const run = async () => {
  //   const res = await runCode({
  //     code,
  //     language,
  //   });
  //   console.log(res.output);
  //   setOutput(res.output);
  // };

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        p: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography variant="body1" component="div">
          Code:
        </Typography>
        <FormControlLabel
          control={
            <DarkModeSwitch
              onChange={handleDarkModeChange}
              sx={{ m: 1 }}
              defaultChecked
            />
          }
          label="Dark mode"
        />
        <FormControl variant="standard" sx={{ minWidth: 120 }}>
          <InputLabel id="select-language">Language</InputLabel>
          <Select
            label="Language"
            variant="standard"
            labelId="select-language"
            value={selectedLanguage}
            onChange={(e) => setSelectedLanguage(e.target.value)}
            inputProps={{ "aria-label": "select language" }}
            sx={{ textTransform: "capitalize" }}
          >
            {Object.keys(availableLanguages).map((lang) => (
              <MenuItem
                key={lang}
                value={lang}
                sx={{ textTransform: "capitalize" }}
              >
                {availableLanguages[lang].title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box
        sx={{
          flex: 2,
          border: "1px solid #33333f",
          width: "100%",
          borderRadius: "5px",
          mt: 1,
          p: 0.11,
        }}
      >
        <Editor
          height={`100%`}
          width={"auto"}
          language={language || "javascript"}
          value={code}
          theme={theme}
          onChange={(code) => setCode(code)}
          options={{
            minimap: { enabled: false },
            fontSize: 16,
            fontFamily: '"Fira code", "Fira Mono", monospace',
            wordWrap: "on",
            scrollBeyondLastLine: false,
            automaticLayout: true,
          }}
        />
      </Box>
      <Button onClick={run} fullWidth>
        Run
      </Button>
      <Typography variant="body1">Output:</Typography>
      <Box
        sx={{
          flex: 2,
          border: "1px solid #33333f",
          width: "100%",
          borderRadius: "5px",
          overflow: "scroll",
        }}
      >
        <Typography sx={{ m: 1 }}>
          {processing ? (
            <em>Processing...</em>
          ) : error ? (
            <pre style={{ color: "red" }}>{error}</pre>
          ) : !output ? (
            <em>Output will appear here</em>
          ) : (
            <pre>{output}</pre>
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export const CodeEditorDrawer = ({ language }) => {
  const [open, setOpen] = useState(false);
  return (
    <Box
      sx={(theme) => ({
        width: open ? "39%" : "auto",
        display: "flex",
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
        position: "fixed",
        height: "95%",
        zIndex: 1,
        backgroundColor: "white",
        mt: -1,
        right: 0,
      })}
    >
      <IconButton
        sx={{
          height: "100%",
          width: "auto",
          borderRadius: 2,
          flexDirection: "column",
        }}
        onClick={() => setOpen(!open)}
      >
        {!open ? (
          <>
            <p
              style={{
                writingMode: "vertical-rl",
                fontSize: 18,
                padding: 0,
                margin: 0,
                marginBottom: 6,
              }}
            >
              CODE
            </p>
            <ChevronLeftIcon />
          </>
        ) : (
          <ChevronRightIcon />
        )}
      </IconButton>
      <Drawer
        open={open}
        variant="persistent"
        anchor="right"
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          position: "relative",
          flex: 1,
          "& .MuiBackdrop-root": {
            display: "none",
          },
          "& .MuiDrawer-paper": {
            width: "100%",
            position: "absolute",
            height: (props) => props.height,
          },
        }}
      >
        <CodeEditor language={language} />
      </Drawer>
    </Box>
  );
};

export default CodeEditor;
