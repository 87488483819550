import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import withAuth from "../hoc/withAuth";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCompanyDetails, updateCompany } from "../api/company";
import Editor from "../components/Editor";
import CompanyBigCard from "../components/CompanyBigCard";
import {
  ExpandMore as ExpandMoreIcon,
  Delete as DeleteIcon,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";
import SelectTopicsForCompanyPopup from "../components/popups/SelectTopicsForCompanyPopup";
import routes from "../layout/routes";
import Loading from "../components/utils/Loading";

const CompanyDetail = () => {
  let { companyId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [content, setContent] = useState(company.content || "");
  const [topicGroups, setTopicGroups] = useState([]);
  const [updateSubTopicsForTopic, setUpdateSubTopicsForTopic] = useState(null);

  useEffect(() => {
    getCompanyDetails(companyId)
      .then((res) => {
        const { company } = res.data;
        setCompany(company);
        setContent(company.content);
        setTopicGroups(company.topics);
      })
      .finally(() => setLoading(false));
  }, [companyId]);

  const handleEdit = () => {
    setEditMode(true);
  };
  const handleCancel = () => {
    setEditMode(false);
    setContent(company.content);
  };

  const handleUpdate = () => {
    setEditMode(false);
    const topics = topicGroups.map((tg) => ({
      title: tg.title,
      subTopicIds: tg.subTopics.map((st) => st._id),
    }));

    updateCompany({ companyId, content, topics }).then((res) => {
      const { company } = res.data;
      setCompany(company);
      setContent(company.content);
      setTopicGroups(company.topics);
    });
  };

  const addNewTopic = () => {
    setTopicGroups([
      ...topicGroups,
      {
        title: "New Topic",
        subTopics: [],
      },
    ]);
  };
  const updateTopicTitle = (index, title) => {
    const newTopicGroups = [...topicGroups];
    newTopicGroups[index].title = title;
    setTopicGroups(newTopicGroups);
  };
  const updateSubTopics = (index, subTopics) => {
    const newTopicGroups = [...topicGroups];
    newTopicGroups[index].subTopics = subTopics;
    setTopicGroups(newTopicGroups);
  };
  const removeSubTopic = (topicIndex, subTopicId) => {
    console.log(topicIndex, subTopicId);
    const newTopicGroups = [...topicGroups];
    const newSubTopics = newTopicGroups[topicIndex].subTopics.filter(
      (st) => st._id !== subTopicId
    );
    console.log(newSubTopics, newTopicGroups[topicIndex].subTopics);
    newTopicGroups[topicIndex].subTopics = newSubTopics;
    setTopicGroups(newTopicGroups);
  };

  return (
    <Box
      height="100%"
      width="100%"
      sx={{
        overflow: "auto",
        pt: 1,
        pb: 1,
      }}
    >
      {loading ? (
        <Loading />
      ) : (
        <Box
          sx={{
            width: "80%",
            display: "flex",
            flexDirection: "column",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 10,
          }}
        >
          <CompanyBigCard
            {...company}
            editMode={editMode}
            handleEdit={handleEdit}
            handleCancel={handleCancel}
            handleUpdate={handleUpdate}
          />

          {editMode ? (
            <Editor value={content} onChange={setContent} />
          ) : (
            <div
              style={{
                height: "auto",
                width: "100%",
                paddingLeft: 10,
                paddingRight: 10,
              }}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}

          {topicGroups.length > 0 && (
            <Typography variant="h6" component="div">
              Topics for learning
            </Typography>
          )}
          {topicGroups.map((topic, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                mt: 1,
              }}
            >
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  {editMode ? (
                    <TextField
                      autoFocus
                      margin="dense"
                      id="title"
                      label="Title"
                      type="text"
                      fullWidth
                      value={topic.title}
                      onChange={(e) => updateTopicTitle(index, e.target.value)}
                    />
                  ) : (
                    <Typography>{topic.title}</Typography>
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    {topic.subTopics.map((subTopic) => (
                      <ListItem
                        key={subTopic._id}
                        disablePadding
                        secondaryAction={
                          editMode ? (
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() =>
                                removeSubTopic(index, subTopic._id)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          ) : (
                            <ArrowForwardIcon />
                          )
                        }
                      >
                        <ListItemButton
                          sx={{ borderRadius: 2 }}
                          onClick={() =>
                            navigate(
                              routes.subTopicDetail.getPath(
                                subTopic.topic.title,
                                subTopic.title
                              )
                            )
                          }
                        >
                          <ListItemText primary={subTopic.title} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
                {editMode && (
                  <AccordionActions>
                    <Button onClick={() => setUpdateSubTopicsForTopic(index)}>
                      Change Sub Topics
                    </Button>
                  </AccordionActions>
                )}
              </Accordion>
            </Box>
          ))}
          {editMode && <Button onClick={addNewTopic}>Add New Topic</Button>}
        </Box>
      )}
      <SelectTopicsForCompanyPopup
        open={updateSubTopicsForTopic !== null}
        handleClose={() => setUpdateSubTopicsForTopic(null)}
        topic={topicGroups[updateSubTopicsForTopic]}
        onUpdate={(subTopics) => {
          console.log(subTopics);
          updateSubTopics(updateSubTopicsForTopic, [...subTopics]);
          setUpdateSubTopicsForTopic(null);
        }}
        initSelectedSubTopics={topicGroups[updateSubTopicsForTopic]?.subTopics}
      />
    </Box>
  );
};

export default withAuth(CompanyDetail);
