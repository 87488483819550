import MoreVertIcon from "@mui/icons-material/MoreVert";
import { LoadingButton } from "@mui/lab";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useContext, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { StoreContext } from "../../App";
import { createPracticeTest } from "../../api/test";
import { FEATURES, testLink } from "../../utils/constants";

export const TakeMockButton = ({
  topicTitle,
  topicId,
  subTopicId,
  hideBorder,
  primaryColor,
}) => {
  const [loading, setLoading] = useState(false);
  const {
    showEnglishPractice,
    state: { user },
  } = useContext(StoreContext);

  const { topicId: topic } = useParams();

  const onPractice = async () => {
    try {
      setLoading(true);
      const res = await createPracticeTest(topicId, subTopicId);
      window.open(testLink(res.testId), "_blank").focus();
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  // topicTitle comes from home page cards
  // topic comes from topic details and subtopic details pages

  if (topic === "English") return null;
  if (
    topicTitle === "English" &&
    user?.features?.includes(FEATURES.ENGLISH_BOT)
  ) {
    return (
      <LoadingButton
        loading={loading}
        disabled={loading}
        variant={hideBorder ? "text" : "outlined"}
        onClick={showEnglishPractice}
        size="small"
        sx={{ color: primaryColor, borderColor: primaryColor }}
      >
        Practice
      </LoadingButton>
    );
  }

  if (
    topicTitle !== "English" &&
    user?.features?.includes(FEATURES.PRACTICE_TESTS)
  ) {
    return (
      <LoadingButton
        loading={loading}
        disabled={loading || !topicId}
        variant={hideBorder ? "text" : "outlined"}
        onClick={onPractice}
        size="small"
        sx={{ color: primaryColor, borderColor: primaryColor }}
      >
        Take Mock
      </LoadingButton>
    );
  }

  return null;
};

export const OptionsButton = ({ actions }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef(null);

  return (
    <>
      <IconButton
        ref={anchorRef}
        aria-label="more"
        id="long-button"
        aria-controls={menuOpen ? "long-menu" : undefined}
        aria-expanded={menuOpen ? "true" : undefined}
        aria-haspopup="true"
        onClick={() => setMenuOpen(true)}
        sx={{ mr: -1 }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorRef?.current}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        {actions.map((action) => (
          <MenuItem
            key={action.title}
            onClick={() => {
              action.onClick();
              setMenuOpen(false);
            }}
          >
            <ListItemIcon>{action.icon}</ListItemIcon>
            <ListItemText>{action.title}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
