import {
  Avatar,
  CardContent,
  CardHeader,
  Paper,
  Typography,
} from "@mui/material";
import { OptionsButton } from "./utils/buttons";
import { red } from "@mui/material/colors";
import { getDate } from "../utils/date.js";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import { InputSend } from "./utils/inputs";

const NoticeBoardPostReplyCard = ({ reply, userId, onEdit, onDelete }) => {
  const [editMode, setEditMode] = useState(false);

  const handleEdit = async (message) => {
    await onEdit(message);
    setEditMode(false);
  };

  return (
    <Paper elevation={0}>
      <CardHeader
        avatar={
          <Avatar
            sx={{ bgcolor: red[500] }}
            src={reply.from.avatar}
            alt={reply.from.name}
          />
        }
        action={
          userId === reply.from._id &&
          !editMode && (
            <OptionsButton
              actions={[
                {
                  title: "Edit",
                  onClick: () => setEditMode(true),
                  icon: <EditIcon />,
                },
                {
                  title: "Delete",
                  onClick: onDelete,
                  icon: <DeleteIcon color="error" />,
                },
              ]}
            />
          )
        }
        title={reply.from.name}
        subheader={getDate(reply.createdAt)}
      />
      <CardContent sx={{ mt: 0, mb: 0, pt: 0, pb: 0 }}>
        {editMode ? (
          <InputSend
            placeholder="Add your reply..."
            initialValue={reply.message}
            onSubmit={handleEdit}
            onCancel={() => setEditMode(false)}
          />
        ) : (
          <Typography>{reply.message}</Typography>
        )}
      </CardContent>
    </Paper>
  );
};

export default NoticeBoardPostReplyCard;
