import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import AddIcon from "@mui/icons-material/AddCircleOutlineRounded";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTopics } from "../api/content";
import { testTypes } from "../utils/constants";
import { convertToMap } from "../utils/object";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const TestForm = ({ isTestProfile, errors: apiErrors, onSubmit }) => {
  const errorRef = useRef(null);

  const [errors, setErrorMessages] = useState([]);
  const [testTitle, setTestTitle] = useState("");
  const [testType, setTestType] = useState(testTypes[0].value);
  const [startTime, setStartTime] = useState(dayjs(new Date()).add(1, "day"));
  const [sectionCount, setSectionCount] = useState(1); // used only as ID in sections map. actual count is always taken from length of `sections` variable
  const [sections, setSections] = useState({});

  const {
    data: { topics },
  } = useTopics();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [lastRemovedSection, setLastRemovedSection] = useState(null);

  useEffect(() => {
    apiErrors && setErrorMessages(apiErrors);
  }, [apiErrors]);

  const topicsMap = useMemo(() => convertToMap(topics, "_id"), [topics]);

  useEffect(() => {
    if (Object.keys(sections).length === 0) {
      addSection();
    }
  }, []);

  const addSection = () => {
    setSections((sections) => {
      const newSections = { ...sections };
      newSections[sectionCount] = {
        title: "",
        topicIds: [],
      };
      setSectionCount(sectionCount + 1);
      return newSections;
    });
  };

  const removeSection = (key) => {
    setSections((sections) => {
      const newSections = { ...sections };
      setLastRemovedSection({
        key: key,
        section: { ...sections[key] },
      });
      delete newSections[key];
      setSnackbarOpen(true);
      setTimeout(() => setSnackbarOpen(false), 3000);
      return newSections;
    });
  };

  const onTitleChange = (event) => {
    setTestTitle(event.target.value);
  };
  const onStartTimeChange = (value) => {
    setStartTime(value);
  };

  const undoDeleteSection = () => {
    setSections((sections) => {
      let newSections = { ...sections };
      newSections[lastRemovedSection.key] = lastRemovedSection.section;
      setLastRemovedSection(null);
      setSnackbarOpen(false);
      return newSections;
    });
  };

  const onSectionTitleChange = (key, event) => {
    const {
      target: { value },
    } = event;
    setSections({
      ...sections,
      [key]: {
        ...sections[key],
        title: value,
      },
    });
  };

  const onSectionQuestionSwitchingChange = (key, event) => {
    const {
      target: { checked },
    } = event;
    console.log({ checked });
    setSections({
      ...sections,
      [key]: {
        ...sections[key],
        allowQuestionSwitching: checked,
      },
    });
  };

  const onSectionTopicsChange = (key, event) => {
    const {
      target: { value },
    } = event;
    setSections({
      ...sections,
      [key]: {
        ...sections[key],
        topicIds: typeof value === "string" ? value.split(",") : value,
      },
    });
    console.log({
      ...sections,
      [key]: {
        ...sections[key],
        topicIds: typeof value === "string" ? value.split(",") : value,
      },
    });
  };

  const onSectionInstructionsChange = (key, data) => {
    setSections({
      ...sections,
      [key]: {
        ...sections[key],
        instructions: data,
      },
    });
  };

  const setNumOfQuestions = (key, event) => {
    const value = Number(event.target.value);
    console.log(value, isNaN(value));
    if (isNaN(value)) {
      return;
    }
    setSections({
      ...sections,
      [key]: {
        ...sections[key],
        numOfQuestions: value,
      },
    });
  };

  const setSectionDuration = (key, event) => {
    const value = Number(event.target.value);
    console.log(value, isNaN(value));
    if (isNaN(value)) {
      return;
    }
    setSections({
      ...sections,
      [key]: {
        ...sections[key],
        duration: value,
      },
    });
  };

  const setErrors = (errors) => {
    setErrorMessages(errors);
    if (errors.length !== 0) {
      errorRef.current.scrollIntoView();
    }
  };

  const validate = () => {
    const errors = [];

    if (!testTitle) {
      errors.push("Test title is required");
    }
    if (!Object.values(sections).length) {
      errors.push("At least one section is required");
    }
    if (Object.values(sections).some((s) => !s.title)) {
      errors.push("Section title is required");
    }
    if (Object.values(sections).some((s) => !s.topicIds)) {
      errors.push("Section topics are required");
    }
    if (Object.values(sections).some((s) => !s.duration)) {
      errors.push("Section duration is required");
    }
    if (Object.values(sections).some((s) => s.duration < 1)) {
      errors.push("Section duration must be greater than 0");
    }
    if (Object.values(sections).some((s) => !s.numOfQuestions)) {
      errors.push("Number of questions is required");
    }
    if (Object.values(sections).some((s) => s.numOfQuestions < 1)) {
      errors.push("Number of questions in a section must be greater than 0");
    }
    if (!isTestProfile && dayjs(startTime).isBefore(dayjs())) {
      errors.push("Start time must be in the future");
    }

    return errors;
  };

  const onCreate = async () => {
    const errors = validate();
    if (errors.length > 0) {
      setErrors(errors);
      return;
    }
    onSubmit({
      title: testTitle,
      testType,
      startTime,
      sections: Object.values(sections),
      allowSectionSwitching: false,
    });
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          overflow: "auto",
          width: "100%",
          height: "100%",
        }}
        ref={errorRef}
      >
        <FormControl
          sx={(theme) => ({
            m: 1,
            pl: "20%",
            pr: "20%",
            width: "100%",
            mt: 2,
            [theme.breakpoints.down("md")]: {
              p: 1,
              m: 1,
            },
          })}
        >
          <Typography variant="h4" sx={{ m: 1 }}>
            {isTestProfile ? "Create Test Profile" : "Create Test"}
          </Typography>
          {errors?.length > 0 && (
            <Alert severity="error" sx={{ m: 1, width: "auto", mt: 2, mb: 4 }}>
              <AlertTitle>Error</AlertTitle>
              <ul>
                {errors.map((e) => (
                  <li key={e}>{e}</li>
                ))}
              </ul>
            </Alert>
          )}
          {!isTestProfile && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label="Start time"
                value={startTime}
                onChange={onStartTimeChange}
                renderInput={(params) => (
                  <TextField sx={{ m: 1 }} {...params} />
                )}
              />
            </LocalizationProvider>
          )}

          <TextField
            id="outlined-basic"
            label="Title"
            value={testTitle}
            onChange={onTitleChange}
            sx={{ m: 1 }}
          />
          {isTestProfile && (
            <Select
              displayEmpty
              value={testType}
              onChange={(e) => setTestType(e.target.value)}
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
              sx={{ m: 1 }}
            >
              {testTypes?.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          )}
          {Object.keys(sections).map((key, index) => (
            <Paper
              key={key}
              variant="outlined"
              sx={{
                display: "flex",
                flexDirection: "column",
                m: 1,
                mt: 3,
              }}
            >
              <Box
                sx={{
                  p: 1,
                  pl: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <Typography variant="h6">Section {index + 1}</Typography>
                <IconButton onClick={() => removeSection(key)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
              <Divider />
              <Box sx={{ p: 3, display: "flex", flexDirection: "column" }}>
                <TextField
                  label="Title"
                  variant="outlined"
                  value={sections[key]?.title}
                  onChange={(event) => onSectionTitleChange(key, event)}
                  sx={{ m: 1, ml: 0, mr: 0 }}
                />
                <Select
                  multiple
                  displayEmpty
                  value={sections[key]?.topicIds || []}
                  onChange={(e) => onSectionTopicsChange(key, e)}
                  renderValue={(selected) => {
                    if (!selected || selected.length === 0)
                      return <em>Select Topics</em>;
                    return selected
                      .map((tId) => topicsMap[tId]?.title)
                      .join(", ");
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ m: 1, ml: 0, mr: 0 }}
                >
                  {topics?.map((topic) => (
                    <MenuItem key={topic._id} value={topic._id}>
                      {topic.title}
                    </MenuItem>
                  ))}
                </Select>
                <TextField
                  label="Number of questions"
                  variant="outlined"
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  value={sections[key]?.numOfQuestions || 0}
                  onChange={(event) => setNumOfQuestions(key, event)}
                  sx={{ m: 1, ml: 0, mr: 0 }}
                />
                <TextField
                  label="Duration (in minutes)"
                  variant="outlined"
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  value={sections[key]?.duration || 0}
                  onChange={(event) => setSectionDuration(key, event)}
                  sx={{ m: 1, ml: 0, mr: 0 }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sections[key]?.allowQuestionSwitching}
                      onChange={(e) => onSectionQuestionSwitchingChange(key, e)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Allow question switching?"
                />
                <CKEditor
                  editor={ClassicEditor}
                  data={sections[key]?.instructions || "<h1>Instructions</h1>"}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    // console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    // console.log({ data });
                    onSectionInstructionsChange(key, data);
                  }}
                />
              </Box>
            </Paper>
          ))}

          <Paper
            variant="outlined"
            sx={{
              m: 1,
              height: 300,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              sx={{ fontSize: "1rem", width: "100%", height: 100 }}
              aria-label="add section"
              color="primary"
              size="large"
              onClick={addSection}
            >
              <AddIcon sx={{ fontSize: 40, mr: 2 }} />
              Add Section
            </Button>
          </Paper>
          <Button
            variant="contained"
            sx={{ m: 1 }}
            onClick={onCreate}
            size="large"
          >
            Create
          </Button>
        </FormControl>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          message="Deleted section"
          action={
            <React.Fragment>
              <Button color="primary" size="small" onClick={undoDeleteSection}>
                UNDO
              </Button>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setSnackbarOpen(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </div>
    </Box>
  );
};

export default TestForm;
