import React, { useContext } from "react";
import {
  Paper,
  Typography,
  IconButton,
  Box,
  LinearProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import withAuth from "../hoc/withAuth";
import { lockStatuses } from "../utils/constants";
import { LockUnlockIcon } from "./utils/icons";

const SubTopicCard = ({
  title,
  primaryColor,
  color,
  openSubTopic,
  editSubTopic,
  deleteSubTopic,
  status = lockStatuses.LOCKED,
  progress = 0,
  isSuperAdmin,
}) => {
  return (
    <Paper
      variant="outlined"
      sx={{
        "&:hover": {
          backgroundColor: color,
        },
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: 68,
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          width: "95%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingLeft: "2.5%",
          paddingRight: "2.5%",
        }}
      >
        <Box
          onClick={openSubTopic}
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Typography
            variant="subtitle1"
            component="div"
            sx={{ fontWeight: 400 }}
          >
            {title}
          </Typography>
        </Box>
        <Box
          sx={{
            marginLeft: "auto",
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          {isSuperAdmin ? (
            <>
              <IconButton
                sx={{ borderRadius: 25, color: primaryColor }}
                aria-label="edit"
                onClick={editSubTopic}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                sx={{ borderRadius: 25, color: primaryColor }}
                aria-label="delete"
                onClick={deleteSubTopic}
              >
                <DeleteIcon />
              </IconButton>
            </>
          ) : (
            <IconButton
              sx={{ borderRadius: 25, color: primaryColor }}
              aria-label="go"
              onClick={openSubTopic}
            >
              <LockUnlockIcon status={status} go />
            </IconButton>
          )}
        </Box>
      </Box>
      <LinearProgress variant="determinate" value={progress || 0} />
    </Paper>
  );
};

export default withAuth(SubTopicCard);
