import { Lock as LockIcon } from "@mui/icons-material";
import { Box, Button, Grid, Stack, Tab, Tabs } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { StoreContext } from "../App";
import {
  deleteArticle,
  deleteFormula,
  deleteVideo,
  editArticle,
  editFormula,
  editVideo,
  editVideoPosition,
  useSubTopicDetails,
  useTopicDetails,
} from "../api/content";
import AddArticle, { ArticleForm } from "../components/AddArticle";
import AddFormula, { FormulaForm } from "../components/AddFormula";
import AddSubTopicVideoCard, {
  VideoForm,
} from "../components/AddSubTopicVideoCard";
import ArticleCard from "../components/ArticleCard";
import Formula from "../components/Formula";
import QuestionCard from "../components/QuestionCard";
import { AddQuestionButton } from "../components/QuestionForm";
import SubTopicBigCard from "../components/SubTopicBigCard";
import SubTopicVideoCard from "../components/SubTopicVideoCard";
import Popup from "../components/popups";
import Loading from "../components/utils/Loading";
import Message from "../components/utils/Message";
import XLink from "../components/utils/link";
import subjects from "../data/subjects";
import routes from "../layout/routes";
import { FEATURES, lockStatuses, plansLink } from "../utils/constants";

const tabs = {
  videos: "Videos",
  formulae: "Formulae",
  questions: "Questions",
  articles: "Articles",
};

const SubTopicDetail = () => {
  const {
    state: { isAuthenticated, user },
  } = useContext(StoreContext);

  const [selectedTab, setSelectedTab] = useState(tabs.videos);
  const [formulaFormOpen, setFormulaFormOpen] = useState(false);
  const [selectedFormulaId, setSelectedFormulaId] = useState(null);
  const [selectedArticleId, setSelectedArticleId] = useState(null);
  const [selectedVideoId, setSelectedVideoId] = useState(null);
  const [videoLockedAlert, setVideoLockedAlert] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  let { topicId, subTopicId } = useParams();

  const {
    data: { subTopic, progress },
    isLoading,
    mutateSubTopic: mutate,
  } = useSubTopicDetails(topicId, subTopicId);
  const {
    data: { topic },
  } = useTopicDetails(topicId);

  console.log({ subTopic });

  const { articleIndex } = location.state || {};

  useEffect(() => {
    if (articleIndex !== undefined) {
      setSelectedTab(tabs.articles);
    }
  }, [articleIndex]);

  const playVideo = (id) => {
    const locked =
      !progress[id]?.status || progress[id]?.status === lockStatuses.LOCKED;
    if (locked && !isSuperAdmin) {
      setVideoLockedAlert(true);
      return;
    }
    navigate(routes.subTopicVideo.getPath(topicId, subTopicId, id), {
      state: { progress },
    });
  };

  const onEditFormulaClick = (formulaId) => {
    setSelectedFormulaId(formulaId);
    setFormulaFormOpen(true);
  };
  const onEditFormula = ({ title, description, formula }) => {
    editFormula({
      formulaId: selectedFormulaId,
      title,
      description,
      formula,
    }).then((res) => {
      console.log(res);
      setSelectedFormulaId(null);
      setFormulaFormOpen(false);
      mutate({
        ...subTopic,
        formulae: subTopic.formulae.map((f) =>
          f._id === selectedFormulaId
            ? { ...f, title, description, formula }
            : f
        ),
      });
    });
  };
  const onDeleteFormula = (formulaId) => {
    deleteFormula(formulaId).then(() => {
      mutate({
        ...subTopic,
        formulae: subTopic.formulae.filter(
          (formula) => formula._id !== formulaId
        ),
      });
    });
  };

  const handleEditArticle = (id) => {
    setSelectedArticleId(id);
  };
  const handleCancelEditArticle = () => {
    setSelectedArticleId(null);
  };
  const onEditArticleSubmit = ({ title, content }) => {
    editArticle({
      articleId: selectedArticleId,
      title,
      content,
    })
      .then((res) => {
        console.log(res);
        handleCancelEditArticle();
        mutate({
          ...subTopic,
          articles: subTopic.articles.map((article) => {
            if (article._id === selectedArticleId) {
              return {
                ...article,
                title,
                content,
              };
            }
            return article;
          }),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDeleteArticle = (id) => {
    deleteArticle(id).then(() => {
      mutate({
        ...subTopic,
        articles: subTopic.articles.filter((article) => article._id !== id),
      });
    });
  };

  const moveVideo = (videoId, shift) => {
    const currentPosition = subTopic.videos.findIndex((v) => v._id === videoId);
    const updatedVideos = subTopic.videos.filter((v) => v._id !== videoId);
    updatedVideos.splice(
      currentPosition + shift,
      0,
      subTopic.videos[currentPosition]
    );

    editVideoPosition({
      videoId,
      subTopicId: subTopic._id,
      position: currentPosition + shift,
    });
    mutate({
      ...subTopic,
      videos: updatedVideos,
    });
  };

  const moveVideoLeft = (videoId) => {
    moveVideo(videoId, -1);
  };

  const moveVideoRight = (videoId) => {
    moveVideo(videoId, 1);
  };

  const onEditVideoClick = (videoId) => {
    setSelectedVideoId(videoId);
  };
  const handleCancelEditVideo = () => {
    setSelectedVideoId(null);
  };
  const onEditVideoSubmit = (data) => {
    const video = subTopic.videos.find(
      (video) => video._id === selectedVideoId
    );

    editVideo({ videoId: selectedVideoId, ...video, ...data })
      .then((res) => {
        console.log(res);
        handleCancelEditVideo();
        mutate({
          ...subTopic,
          videos: subTopic.videos.map((video) => {
            if (video._id === selectedVideoId) {
              return {
                ...video,
                ...data,
              };
            }
            return video;
          }),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDeleteVideo = (videoId) => {
    deleteVideo(videoId).then(() => {
      mutate({
        ...subTopic,
        videos: subTopic.videos.filter((video) => video._id !== videoId),
      });
    });
  };

  const subject = subjects[topicId];

  const isSuperAdmin = isAuthenticated && user.role === "superadmin";
  const selectedArticle = subTopic?.articles?.find(
    (article) => article._id === selectedArticleId
  );
  const selectedVideo = subTopic?.videos?.find(
    (video) => video._id === selectedVideoId
  );

  const articles = user?.features?.includes(FEATURES.VIEW_CONTENT)
    ? subTopic?.articles
    : subTopic?.articles?.slice(0, 3);

  const formulae = user?.features?.includes(FEATURES.VIEW_CONTENT)
    ? subTopic?.formulae
    : subTopic?.formulae?.slice(0, 3);

  return (
    <Box
      height="100%"
      width="auto"
      sx={(theme) => ({
        display: "flex",
        flexDirection: "row",
        overflow: "hidden",
        p: 0,
        pt: 1,
        pb: 1,
        [theme.breakpoints.down("md")]: {
          p: 0,
          m: 0,
        },
      })}
    >
      {isSuperAdmin && (
        <>
          <FormulaForm
            open={formulaFormOpen}
            onSubmit={onEditFormula}
            handleClose={() => setFormulaFormOpen(false)}
            obj={subTopic?.formulae?.find((f) => f._id === selectedFormulaId)}
          />
          <ArticleForm
            open={!!selectedArticleId}
            subTopicId={subTopic._id}
            obj={selectedArticle}
            onSubmit={onEditArticleSubmit}
            handleClose={handleCancelEditArticle}
          />
          <VideoForm
            open={!!selectedVideoId}
            subTopicId={subTopic._id}
            obj={selectedVideo}
            onSubmit={onEditVideoSubmit}
            handleClose={handleCancelEditVideo}
          />
        </>
      )}
      <Popup
        open={videoLockedAlert}
        onClose={() => setVideoLockedAlert(false)}
        title={"Video locked"}
        content={
          "This video is locked. Please complete the previous videos to unlock this video."
        }
        actions={[
          <Button onClick={() => setVideoLockedAlert(false)}>OK</Button>,
        ]}
      />
      {/* <ContentTableDrawer />
      <Divider
        orientation="vertical"
        flexItem
        sx={(theme) => ({
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        })}
      /> */}
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              overflow: "auto",
              pl: 5,
              pr: 5,
            }}
          >
            <Stack
              spacing={2}
              alignItems="center"
              sx={(theme) => ({
                ml: 5,
                mr: 5,
                pt: 1,
                pb: 1,
                [theme.breakpoints.down("md")]: {
                  p: 1,
                  m: 1,
                },
              })}
            >
              <SubTopicBigCard
                id={subTopic._id}
                topicId={topic._id}
                title={subTopic.title}
                description={subTopic.description}
                color={subject.color}
                primaryColor={subject.primaryColor}
              />
              <Tabs
                value={selectedTab}
                onChange={(e, newValue) => setSelectedTab(newValue)}
                variant="fullWidth"
                sx={{ width: "100%" }}
              >
                {subTopic?.kinds?.map((kind) => (
                  <Tab key={kind} label={tabs[kind]} value={tabs[kind]} />
                ))}
                {isSuperAdmin && (
                  <Tab label={tabs.questions} value={tabs.questions} />
                )}
              </Tabs>
              {selectedTab === tabs.formulae ? (
                <Stack sx={{ width: "100%" }} spacing={2}>
                  {isSuperAdmin && (
                    <AddFormula subTopicId={subTopic._id} onAdd={mutate} />
                  )}
                  {formulae?.map((f) => (
                    <Formula
                      key={f._id}
                      obj={f}
                      editFormula={() => onEditFormulaClick(f._id)}
                      deleteFormula={() => onDeleteFormula(f._id)}
                    />
                  ))}
                  {!user.features.includes(FEATURES.VIEW_CONTENT) && (
                    <Message
                      icon={<LockIcon fontSize="large" />}
                      message="Please upgrade to view more content."
                      extra={
                        <XLink to={plansLink} external>
                          Upgrade
                        </XLink>
                      }
                    />
                  )}
                  {isSuperAdmin && subTopic?.formulae.length > 0 && (
                    <AddFormula subTopicId={subTopic._id} onAdd={mutate} />
                  )}
                </Stack>
              ) : selectedTab === tabs.videos ? (
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  flexWrap="wrap"
                  sx={{ width: "100%" }}
                  spacing={2}
                >
                  {isSuperAdmin && (
                    <Grid item xs={6} sm={6} md={4} lg={3}>
                      <AddSubTopicVideoCard
                        subTopicId={subTopic._id}
                        onAdd={mutate}
                      />
                    </Grid>
                  )}
                  {subTopic?.videos?.map((v, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={v._id}>
                      <SubTopicVideoCard
                        obj={v}
                        playVideo={() => playVideo(v._id)}
                        index={index}
                        moveLeft={() => moveVideoLeft(v._id)}
                        moveRight={() => moveVideoRight(v._id)}
                        editVideo={() => onEditVideoClick(v._id)}
                        deleteVideo={() => handleDeleteVideo(v._id)}
                        playedFraction={progress[v._id]?.playedFraction}
                        status={progress[v._id]?.status}
                      />
                    </Grid>
                  ))}
                  {isSuperAdmin && subTopic?.videos?.length > 0 && (
                    <Grid item xs={6} sm={6} md={4} lg={3}>
                      <AddSubTopicVideoCard
                        subTopicId={subTopic._id}
                        onAdd={mutate}
                      />
                    </Grid>
                  )}
                </Grid>
              ) : selectedTab === tabs.questions ? (
                <>
                  {isSuperAdmin && (
                    <AddQuestionButton subTopicId={subTopic._id} />
                  )}
                  {subTopic?.questions?.map((q) => (
                    <QuestionCard
                      key={q._id}
                      subTopicId={subTopic._id}
                      obj={q}
                      color={subject.color}
                    />
                  ))}
                  {isSuperAdmin && subTopic?.questions?.length > 0 && (
                    <AddQuestionButton subTopicId={subTopic._id} />
                  )}
                </>
              ) : selectedTab === tabs.articles ? (
                <Box sx={{ width: "100%" }}>
                  {isSuperAdmin && (
                    <AddArticle subTopicId={subTopic._id} onAdd={mutate} />
                  )}
                  {articles?.map((a) => (
                    <ArticleCard
                      key={a._id}
                      obj={a}
                      handleEdit={() => handleEditArticle(a._id)}
                      handleDelete={() => handleDeleteArticle(a._id)}
                    />
                  ))}
                  {!user.features.includes(FEATURES.VIEW_CONTENT) && (
                    <Message
                      icon={<LockIcon fontSize="large" />}
                      message="Please upgrade to view more content."
                      extra={
                        <XLink to={plansLink} external>
                          Upgrade
                        </XLink>
                      }
                    />
                  )}
                  {isSuperAdmin && subTopic?.articles?.length > 0 && (
                    <AddArticle subTopicId={subTopic._id} onAdd={mutate} />
                  )}
                </Box>
              ) : null}
            </Stack>
          </Box>
        </>
      )}
    </Box>
  );
};

export default SubTopicDetail;
