import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { lockStatuses } from "../../utils/constants";
import { Tooltip } from "@mui/material";

export const LockUnlockIcon = ({ status, go }) => {
  return status === lockStatuses.UNLOCKED ? (
    go ? (
      <ArrowForwardIcon />
    ) : (
      <LockOpenIcon />
    )
  ) : status === lockStatuses.FINISHED ? (
    <Tooltip title="Completed">
      <CheckCircleIcon color="success" />
    </Tooltip>
  ) : (
    <LockIcon />
  );
};
