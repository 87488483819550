import { useContext } from "react";
import { StoreContext } from "../App";
import PreparationPlanHome from "./PreparationPlanHome";
import TopicsHome from "./TopicsHome";
import { homeViews } from "../utils/constants";

const Home = () => {
  const {
    state: { homeView },
  } = useContext(StoreContext);

  if (homeView === homeViews.TOPICS) {
    return <TopicsHome />;
  }

  if (homeView === homeViews.PREPARATION_PLAN) {
    return <PreparationPlanHome />;
  }

  return <TopicsHome />;
};

export default Home;
