import {
  Box,
  Grid,
  List,
  ListItem,
  ListSubheader,
  Skeleton,
} from "@mui/material";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTopics } from "../api/content";
import TopicCard from "../components/TopicCard";
import subjects from "../data/subjects";
import routes from "../layout/routes";
import { sortByAscending } from "../utils/object";
import styles from "./styles";

const Home = () => {
  let navigate = useNavigate();
  // const [companies, setCompanies] = useState([]);
  // const [companiesLoading, setCompaniesLoading] = useState(true);

  const {
    data: { topics, progress },
    isLoading: topicsLoading,
  } = useTopics();
  const { data } = useTopics();

  console.log("data", data);
  console.log("topics", topics);

  const topicGroups = useMemo(() => {
    const topicGroups = {};

    sortByAscending(topics, "position").forEach((topic) => {
      const { group = "Others" } = subjects[topic.title];
      if (topicGroups[group]) {
        topicGroups[group].push(topic);
      } else {
        topicGroups[group] = [topic];
      }
    });
    return topicGroups;
  }, [topics]);

  const onLearn = (id) => {
    navigate(routes.topicDetail.getPath(id));
  };

  const onLearnCompany = (companyId) => {
    navigate(routes.companyDetail.getPath(companyId));
  };

  const onPracticeCompany = (companyId) => {
    // TODO: create practice test of company
  };

  // useEffect(() => {
  //   getCompanies().then((res) => {
  //     const { companies } = res.data;
  //     setCompanies(companies);
  //     setCompaniesLoading(false);
  //   });
  // }, []);

  return (
    <List
      sx={(theme) => ({
        height: "100%",
        width: "auto",
        overflowY: "auto",
        paddingLeft: 5,
        paddingRight: 5,
        mt: 1,
        pb: 1,
        [theme.breakpoints.down("md")]: {
          p: 0,
          m: 0,
        },
      })}
      subheader={<li />}
    >
      {topicsLoading ? (
        <XSkeleton />
      ) : (
        Object.keys(topicGroups).map((groupTitle) => {
          const topics = topicGroups[groupTitle];
          return (
            <Box
              key={groupTitle}
              sx={(theme) => ({
                display: "flex",
                flexDirection: "column",
                width: "100%",
              })}
            >
              <ListSubheader sx={{ background: "inherit" }}>
                {groupTitle}
              </ListSubheader>
              <ListItem>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                  // sx={(theme) => ({
                  //   [theme.breakpoints.down("sm")]: {
                  //     flexDirection: "column",
                  //     justifyContent: "center",
                  //     alignItems: "center",
                  //   },
                  // })}
                >
                  {topics.map((topic) => {
                    const title = topic.title;
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        xl={3}
                        key={title}
                      >
                        <TopicCard
                          id={topic._id}
                          title={title}
                          thumbnail={subjects[title]?.thumbnail}
                          description={topic.description}
                          color={subjects[title]?.color}
                          primaryColor={subjects[title]?.primaryColor}
                          secondaryColor={subjects[title]?.secondaryColor}
                          onLearn={() => onLearn(title)}
                          status={progress[topic._id]?.status}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </ListItem>
            </Box>
          );
        })
      )}

      {/* {companiesLoading ? (
        <XSkeleton />
      ) : (
        companies?.length > 0 && (
          <>
            <ListSubheader>Companies</ListSubheader>
            <ListItem>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                {companies.map((company) => {
                  const { _id: companyId, title } = company;
                  const { thumbnail, primaryColor, secondaryColor, color } =
                    companiesData[title.toLowerCase()];
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      xl={3}
                      key={companyId}
                    >
                      <CompanyCard
                        title={title}
                        thumbnail={thumbnail}
                        color={color}
                        primaryColor={primaryColor}
                        secondaryColor={secondaryColor}
                        onLearn={() => onLearnCompany(companyId)}
                        onPractice={() => onPracticeCompany(companyId)}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </ListItem>
          </>
        )
      )} */}
    </List>
  );
};

const XSkeleton = () => {
  return (
    <>
      <ListSubheader>
        <Skeleton width={140} />
      </ListSubheader>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <Skeleton variant="rounded" sx={styles.topicCard} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <Skeleton variant="rounded" sx={styles.topicCard} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <Skeleton variant="rounded" sx={styles.topicCard} />
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
