import { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { createSubTopic } from "../api/content";

export const SubTopicForm = ({ open, subTopic, handleClose, onSubmit }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [locked, setLocked] = useState(false);

  useEffect(() => {
    if (subTopic) {
      setTitle(subTopic.title);
      setDescription(subTopic.description);
      setLocked(subTopic.locked);
    }
  }, [subTopic]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{subTopic ? "Add" : "Edit"} Sub-Topic</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="title"
          label="Title"
          type="text"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          margin="dense"
          id="description"
          label="Description"
          type="text"
          fullWidth
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={locked}
              onChange={(e) => setLocked(e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Locked by default?"
        />
      </DialogContent>
      <DialogActions sx={{ display: "flex" }}>
        <Button sx={{ flex: 1 }} onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          sx={{ flex: 1 }}
          variant="outlined"
          onClick={() => onSubmit(title, description, locked)}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AddSubTopic = ({ topicId, color, onAdd }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleAdd = (title, description, locked) => {
    createSubTopic({ topicId, title, description, locked })
      .then((res) => onAdd(res.subTopic))
      .then(handleClose);
  };

  return (
    <>
      <SubTopicForm
        topicId={topicId}
        open={open}
        handleClose={handleClose}
        onSubmit={handleAdd}
      />
      <Button sx={{ color }} onClick={handleClickOpen}>
        Add
      </Button>
    </>
  );
};

export default AddSubTopic;
